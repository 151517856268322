import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getCertificateBack = () => (dispatch) => {
  dispatch(setLoadingGetCertificateBack(true));

  const success = (data) => {
    dispatch(setDataGetCertificateBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetCertificateBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/certificates`,
    success,
    setError,
  });
};

const initialState = {
  dataGetCertificateBack: null,
  loadingGetCertificateBack: false,
  errorGetCertificateBack: null,
};

export const certificate = createSlice({
  name: "certificate",
  initialState,
  reducers: {
    setDataGetCertificateBack(state, action) {
      state.dataGetCertificateBack = action.payload;
      state.loadingGetCertificateBack = false;
      state.errorGetCertificateBack = null;
    },

    setErrorGetCertificateBack(state, action) {
      state.errorGetCertificateBack = action.payload;
      state.loadingGetCertificateBack = false;
    },
    setLoadingGetCertificateBack(state, action) {
      state.loadingGetCertificateBack = action.payload;
      state.errorGetCertificateBack = null;
    },
  },
});

export const {
  setLoadingGetCertificateBack,
  setErrorGetCertificateBack,
  setDataGetCertificateBack,
} = certificate.actions;

export default certificate.reducer;
