import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./ErrorModal.module.scss";

//commons
import Title from "../../Title";

//redux
import { setModalWindowStatus } from "../../../../ReduxToolkit/App/app";

export const ErrorModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const error = useSelector((state) => state.app.modalWindowInfo);

  return (
    <div className={styles.modal}>
      {error?.title && <Title label={error?.title} />}
      {error?.label && <div className={styles.text}>{t(error?.label)}</div>}

      <button
        className={styles.btn}
        onClick={() => dispatch(setModalWindowStatus(false))}
      >
        {t("modalWindows.success.button")}
      </button>
    </div>
  );
};
