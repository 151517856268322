import React from "react";

//styles
import styles from "./BurgerButton.module.scss";

export const BurgerButton = ({
  focus = false,
  setFocus = console.log("work"),
}) => {
  return (
    <div onClick={() => setFocus()} className={styles.burger}>
      <span
        className={`${styles.burger__line_long} ${focus ? styles.active : ""}`}
      ></span>
      <span
        className={`${styles.burger__line} ${focus ? styles.active : ""}`}
      ></span>
      <span
        className={`${styles.burger__line_long} ${focus ? styles.active : ""}`}
      ></span>
    </div>
  );
};
