import { useTranslation } from "react-i18next";

//styles
import s from "./ErrorBoundaryComponent.module.scss";

//Routes

const language = localStorage.getItem("language_UCGA");

const ErrorComponent = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <main
      role="alert"
      className={`${s.main} ${
        language === "en" ? s.rootFontOne : s.rootFontTwo
      }`}
    >
      <span>Something went wrong:</span>
      <p>{error.message}</p>
      <div className={s.groupBtn}>
        <button onClick={() => resetErrorBoundary()} className={s.tryAgain}>
          Try again
        </button>

        <button
          // onClick={() => history.push(pathFC(path.home))}

          className={s.comeBack}
        >
          {t("error.btn")}
        </button>
      </div>
    </main>
  );
};

export default ErrorComponent;
