import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import styles from "./AuthModal.module.scss";

//commons
import ModalTitle from "../ModalTitle";
import { AuthFormSchema } from "./AuthFormSchema";

//components
import Input from "../../Inputs/Input/Input";

//redux
import { postUserLoginLoginBack } from "../../../../ReduxToolkit/User/Login/Login";
import { setModalWindowChildrenType } from "../../../../ReduxToolkit/App/app";

const nameFields = {
  email: "email",
  password: "password",
};

export const AuthModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { errorPostUserLoginBack } = useSelector((state) => state.userLogin);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(AuthFormSchema(t)),
  });

  const onSubmit = (data) => {
    dispatch(postUserLoginLoginBack(data));
  };

  return (
    <div className={styles.auth}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle title={t("modalWindows.auth.title")} />

        <Input
          width={{ xl: "100%" }}
          id={nameFields.email}
          name={nameFields.email}
          type="email"
          placeholder={t("modalWindows.register.inputs.email")}
          error={errors?.email}
          register={register}
        />

        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.password}
          name={nameFields.password}
          type="password"
          placeholder={t("modalWindows.auth.inputs.password")}
          error={errors?.password}
          register={register}
        />
        <div className={styles.help_links}>
          <span
            onClick={() =>
              dispatch(setModalWindowChildrenType("forgotPassword"))
            }
          >
            {t("modalWindows.auth.helps.forgotPassword")}
          </span>
          <span
            onClick={() => dispatch(setModalWindowChildrenType("register"))}
          >
            {t("modalWindows.auth.helps.register")}
          </span>
        </div>
        {errorPostUserLoginBack?.message ===
          "Request failed with status code 401" && (
          <div className={styles.warning}>{t("modalWindows.auth.warning")}</div>
        )}
        <button className={styles.btn} type="submit">
          {t("modalWindows.auth.button")}
        </button>
      </form>
    </div>
  );
};
