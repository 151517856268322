import { useState } from "react";
import { useTranslation } from "react-i18next";

//styles
import s from "./input.module.scss";

import StyledDiv from "../../StyledDiv/StyledDiv";

const Input = ({
  width,
  label,
  type,
  name,
  placeholder,
  className,
  marginTop,
  errorFromBack,
  required,
  error,
  register,
  marginLeft,
  onChange,
  maxWidth,
  validation,
  icon,
  value,
  disabled,
  marginRight,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [typefieldPasword, setTypefieldPasword] = useState("password");

  const showHidePasswoedHandler = () => {
    setShowPassword((prev) => !prev);
    showPassword
      ? setTypefieldPasword("password")
      : setTypefieldPasword("text");
  };

  return (
    <StyledDiv
      maxWidth={maxWidth && maxWidth}
      width={width && width}
      marginTop={marginTop && marginTop}
      marginLeft={marginLeft && marginLeft}
      marginRight={marginRight && marginRight}
    >
      <div className={`${s.root}  ${className ? className : ""} `}>
        {label ? (
          <label htmlFor={label}>
            {label}
            {required ? <span className={s.required}>*</span> : ""}
          </label>
        ) : (
          ""
        )}
        {register && name ? (
          <div
            className={`${s.wrapperInput} ${
              !!error || errorFromBack ? s.errorInput : ""
            }`}
          >
            <input
              disabled={disabled}
              id={label}
              placeholder={placeholder}
              name={name}
              type={type === "password" ? typefieldPasword : type}
              {...register(name, validation)}
              className={` ${disabled ? s.disabled : ""}`}
            />
            {type === "password" && (
              <div
                className={s.showHide}
                onClick={() => showHidePasswoedHandler()}
              >
                {showPassword ? t("hide") : t("show")}
              </div>
            )}
            {icon && <div className={s.icon}>{icon}</div>}
          </div>
        ) : (
          <div
            className={`${s.wrapperInput} ${
              !!error || errorFromBack ? s.errorInput : ""
            }`}
          >
            <input
              disabled={disabled}
              id={label}
              placeholder={placeholder}
              name={name}
              value={value}
              type={type === "password" ? typefieldPasword : type}
              onChange={(e) => onChange(e.target.value)}
            />
            {type === "password" && (
              <div
                className={s.showHide}
                onClick={() => showHidePasswoedHandler()}
              >
                {showPassword ? t("hide") : t("show")}
              </div>
            )}
          </div>
        )}
        {!!error && (
          <div className={s.error}>
            <span>{error?.message}</span>
          </div>
        )}
        {!!errorFromBack && (
          <div className={s.error}>
            <span>{errorFromBack}</span>
          </div>
        )}
      </div>
    </StyledDiv>
  );
};

export default Input;
