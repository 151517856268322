import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

//styles
import styles from "./HeaderMobileMenu.module.scss";

//images
import { arrowDownIco } from "../../../ImageConstants/imageConstants";

//utils
import { IncludeMassElement } from "../../../Utils/IncludeMassElement";

//redux
import { setFocusBurger } from "../../../ReduxToolkit/App/Header/Header";
//app
import { PRODUCTS_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

//api
import { baseURLSingle } from "../../../API/api";

const HeaderMobileProductsMenuItem = ({
  id,
  image,
  name,
  children,
  slug,
  closeMenu,
}) => {
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState([]);

  const handleonClickShow = (element) => {
    IncludeMassElement(element, showMore, setShowMore);
  };

  const setFocus = () => {
    dispatch(setFocusBurger(false));
    closeMenu(false);
  };
  const slugParent = slug;
  return (
    <li className={styles.products__menu__item}>
      <div className={styles.products__top}>
        <div className={styles.products__top_img}>
          <picture>
            <source
              srcSet={image.webp && `${baseURLSingle}/${encodeURI(image.webp.trim())}`}
              type="image/webp"
            />
            <img
              className={styles.menu__img}
              src={image.src && `${baseURLSingle}/${image.src}`}
              alt="product"
            />
          </picture>
        </div>
        <div>
          <h4
            className={styles.products__title}
            onClick={() => handleonClickShow(id)}
          >
            {name}
            <img
              className={`${styles.products__title__arrow} ${
                showMore.includes(id) && styles.active
              }`}
              src={arrowDownIco}
              alt="arrow"
            />
          </h4>
          <ul
            className={`${styles.products__submenu} ${
              showMore.includes(id) && styles.active
            }`}
          >
            {children.map(({ id, slug, name }) => (
              <li key={id}>
                <NavLink
                  className={styles.products__submenu__link}
                  to={pathFC(`${PRODUCTS_PAGE_ROUTE}/${slugParent}/${slug}`)}
                  onClick={() => setFocus()}
                >
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  );
};

export default HeaderMobileProductsMenuItem;
