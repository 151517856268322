import { mailIco, markerIco } from "../../ImageConstants/imageConstants";

import {
  BLOG_PAGE_ROUTE,
  COMPANY_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  PRODUCTS_PAGE_ROUTE,
  VIDEO_PAGE_ROUTE,
  DELIVERY_PAGE_ROUTE,
} from "../../Components/App/Routes/routesConstants";

import { phoneNumbersData, positionCompanyOnMap } from "../contactsData";

export const footerMenu = [
  { id: 1, url: BLOG_PAGE_ROUTE, label: "footer.navigation.blog" },
  { id: 2, url: NEWS_PAGE_ROUTE, label: "footer.navigation.news" },
  { id: 3, url: VIDEO_PAGE_ROUTE, label: "footer.navigation.video" },
  { id: 4, url: PRODUCTS_PAGE_ROUTE, label: "footer.navigation.products" },
  { id: 5, url: COMPANY_PAGE_ROUTE, label: "footer.navigation.company" },
  { id: 6, url: CONTACTS_PAGE_ROUTE, label: "footer.navigation.contacts" },
  { id: 7, url: DELIVERY_PAGE_ROUTE, label: "footer.navigation.delivery" },
];

export const contactsFooterData = [
  ...phoneNumbersData,
  {
    id: 4,
    label: "info@vectortool.ua",
    url: "mailto:info@vectortool.ua",
    img: mailIco,
    alt: "mail",
  },
  {
    id: 5,
    label: "commons.contacts.address",
    url: `https://www.google.com/maps/dir/?api=1&destination=${positionCompanyOnMap.lat},${positionCompanyOnMap.lng}`,
    img: markerIco,
    alt: "address",
    blank: true,
  },
];
