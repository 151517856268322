import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/api";

//app
import {ERROR_PAGE_ROUTE, pathFC} from "../../../Components/App/Routes/routesConstants";

export const getSubCategoryGoodsBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetSubCategoryGoodsBack(true));

  const success = (data) => {
    dispatch(
      setDataGetSubCategoryGoodsBack({
        data: data.data,
        meta: data.meta,
      })
    );
  };

  const setError = () => {
    window.location.href = pathFC(ERROR_PAGE_ROUTE);
  };

  getRequest({
    url: `/categories/${slug}/products`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetSubCategoryGoodsBack: null,
  loadingGetSubCategoryGoodsBack: false,
  errorGetSubCategoryGoodsBack: null,
  metaGetSubCategoryGoodsBack: null,
};

export const subCategoryGoods = createSlice({
  name: "subCategoryGoods",
  initialState,
  reducers: {
    setDataGetSubCategoryGoodsBack(state, action) {
      state.dataGetSubCategoryGoodsBack = action.payload.data;
      state.metaGetSubCategoryGoodsBack = action.payload.meta;
      state.loadingGetSubCategoryGoodsBack = false;
      state.errorGetSubCategoryGoodsBack = null;
    },

    setErrorGetSubCategoryGoodsBack(state, action) {
      state.errorGetSubCategoryGoodsBack = action.payload;
      state.loadingGetSubCategoryGoodsBack = false;
      state.metaGetSubCategoryGoodsBack = false;
    },
    setLoadingGetSubCategoryGoodsBack(state, action) {
      state.loadingGetSubCategoryGoodsBack = action.payload;
      state.errorGetSubCategoryGoodsBack = null;
    },
  },
});

export const {
  setLoadingGetSubCategoryGoodsBack,
  setErrorGetSubCategoryGoodsBack,
  setDataGetSubCategoryGoodsBack,
} = subCategoryGoods.actions;

export default subCategoryGoods.reducer;
