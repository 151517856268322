import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getPressBack = (params) => (dispatch) => {
  dispatch(setLoadingGetPressBack(true));

  const success = (data) => {
    dispatch(setDataGetPressBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetPressBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/presses`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetPressBack: null,
  loadingGetPressBack: false,
  errorGetPressBack: null,
};

export const press = createSlice({
  name: "press",
  initialState,
  reducers: {
    setDataGetPressBack(state, action) {
      state.dataGetPressBack = action.payload;
      state.loadingGetPressBack = false;
      state.errorGetPressBack = null;
    },

    setErrorGetPressBack(state, action) {
      state.errorGetPressBack = action.payload;
      state.loadingGetPressBack = false;
    },
    setLoadingGetPressBack(state, action) {
      state.loadingGetPressBack = action.payload;
      state.errorGetPressBack = null;
    },
  },
});

export const {
  setLoadingGetPressBack,
  setErrorGetPressBack,
  setDataGetPressBack,
} = press.actions;

export default press.reducer;
