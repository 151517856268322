import {
  BLOG_PAGE_ROUTE,
  COMPANY_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  VIDEO_PAGE_ROUTE,
  DELIVERY_PAGE_ROUTE,
  GUARANTEE_PAGE_ROUTE,
  PRIVACY_PAGE_ROUTE,
  OFFER_PAGE_ROUTE
} from "../Components/App/Routes/routesConstants";

import {
  productImg1,
  productImg2,
  productImg3,
} from "../ImageConstants/imageConstants";

export const languageData = [
  { key: "uk", label: "укр" },
  { key: "ru", label: "рус" },
];

export const navigationData = [
  { id: 1, url: BLOG_PAGE_ROUTE, label: "header.navigation.blog" },
  { id: 2, url: NEWS_PAGE_ROUTE, label: "header.navigation.news" },
  { id: 3, url: VIDEO_PAGE_ROUTE, label: "header.navigation.video" },
  { id: 4, url: COMPANY_PAGE_ROUTE, label: "header.navigation.company" },
  { id: 5, url: CONTACTS_PAGE_ROUTE, label: "header.navigation.contacts" },
  { id: 6, url: DELIVERY_PAGE_ROUTE, hidepc: true, label: "header.navigation.delivery" },
  { id: 7, url: GUARANTEE_PAGE_ROUTE, hidepc: true, label: "header.navigation.guarantee" },
  { id: 8, url: PRIVACY_PAGE_ROUTE, hidepc: true, label: "header.navigation.privacy" },
  { id: 9, url: OFFER_PAGE_ROUTE, hidepc: true, label: "header.navigation.offer" },
  { id: 10, label: "header.navigation.info", hidemobile: true, childs: [
    { id: 11, url: DELIVERY_PAGE_ROUTE, label: "header.navigation.delivery" },
    { id: 12, url: GUARANTEE_PAGE_ROUTE, label: "header.navigation.guarantee" },
    { id: 13, url: PRIVACY_PAGE_ROUTE, label: "header.navigation.privacy" },
    { id: 14, url: OFFER_PAGE_ROUTE, label: "header.navigation.offer" },
  ]},
];

export const productMenuData = [
  {
    id: 1,
    img: productImg1,
    title: "header.productsMenu.product_1.title",
    listActive: false,
    list: [
      { id: 1, url: "/", label: "header.productsMenu.product_1.name_1" },
      { id: 2, url: "/", label: "header.productsMenu.product_1.name_2" },
      { id: 3, url: "/", label: "header.productsMenu.product_1.name_3" },
      { id: 4, url: "/", label: "header.productsMenu.product_1.name_4" },
    ],
  },
  {
    id: 2,
    img: productImg2,
    title: "header.productsMenu.product_2.title",
    listActive: false,
    list: [
      { id: 1, url: "/", label: "header.productsMenu.product_2.name_1" },
      { id: 2, url: "/", label: "header.productsMenu.product_2.name_2" },
      { id: 3, url: "/", label: "header.productsMenu.product_2.name_3" },
      { id: 4, url: "/", label: "header.productsMenu.product_2.name_4" },
      { id: 5, url: "/", label: "header.productsMenu.product_2.name_5" },
      { id: 6, url: "/", label: "header.productsMenu.product_2.name_6" },
    ],
  },
  {
    id: 3,
    img: productImg3,
    title: "header.productsMenu.product_3.title",
    listActive: false,
    list: [
      { id: 1, url: "/", label: "header.productsMenu.product_3.name_1" },
      { id: 2, url: "/", label: "header.productsMenu.product_3.name_2" },
      { id: 3, url: "/", label: "header.productsMenu.product_3.name_3" },
      { id: 4, url: "/", label: "header.productsMenu.product_3.name_4" },
      { id: 5, url: "/", label: "header.productsMenu.product_3.name_5" },
      { id: 6, url: "/", label: "header.productsMenu.product_3.name_6" },
      { id: 7, url: "/", label: "header.productsMenu.product_3.name_7" },
      { id: 8, url: "/", label: "header.productsMenu.product_3.name_8" },
      { id: 9, url: "/", label: "header.productsMenu.product_3.name_9" },
      { id: 10, url: "/", label: "header.productsMenu.product_3.name_10" },
      { id: 11, url: "/", label: "header.productsMenu.product_3.name_11" },
      { id: 12, url: "/", label: "header.productsMenu.product_3.name_12" },
    ],
  },
  {
    id: 4,
    img: productImg1,
    title: "header.productsMenu.product_4.title",
    listActive: false,
    list: [
      { id: 1, url: "/", label: "header.productsMenu.product_4.name_1" },
      { id: 2, url: "/", label: "header.productsMenu.product_4.name_2" },
      { id: 3, url: "/", label: "header.productsMenu.product_4.name_3" },
      { id: 4, url: "/", label: "header.productsMenu.product_4.name_4" },
      { id: 5, url: "/", label: "header.productsMenu.product_4.name_5" },
      { id: 6, url: "/", label: "header.productsMenu.product_4.name_6" },
    ],
  },
  {
    id: 5,
    img: productImg3,
    title: "header.productsMenu.product_5.title",
    listActive: false,
    list: [
      { id: 1, url: "/", label: "header.productsMenu.product_5.name_1" },
      { id: 2, url: "/", label: "header.productsMenu.product_5.name_2" },
      { id: 3, url: "/", label: "header.productsMenu.product_5.name_3" },
      { id: 4, url: "/", label: "header.productsMenu.product_5.name_4" },
    ],
  },
];
