import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getSeoBack = (template, slug, params) => (dispatch) => {
  dispatch(setLoadingGetSeoBack(true));

  const success = (data) => {
    dispatch(setDataGetSeoBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetSeoBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: slug.length ? `/seo/${template}/${slug}` : `/seo/${template}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetSeoBack: null,
  loadingGetSeoBack: false,
  errorGetSeoBack: null,
};

export const seo = createSlice({
  name: "seo",
  initialState,
  reducers: {
    setDataGetSeoBack(state, action) {
      state.dataGetSeoBack = action.payload;
      state.loadingGetSeoBack = false;
      state.errorGetSeoBack = null;
    },
    setErrorGetSeoBack(state, action) {
      state.errorGetSeoBack = action.payload;
      state.loadingGetSeoBack = false;
    },
    setLoadingGetSeoBack(state, action) {
      state.loadingGetSeoBack = action.payload;
      state.errorGetSeoBack = null;
    },
  },
});

export const {
  setLoadingGetSeoBack,
  setErrorGetSeoBack,
  setDataGetSeoBack,
} = seo.actions;

export default seo.reducer;
