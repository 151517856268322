import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/api";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

export const getPopularGoodsBack = (params) => (dispatch) => {
  dispatch(setLoadingGetPopularGoodsBack(true));

  const success = (data) => {
    dispatch(setDataGetPopularGoodsBack(data.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetPopularGoodsBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/popular_products`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetPopularGoodsBack: [],
  loadingGetPopularGoodsBack: false,
  errorGetPopularGoodsBack: null,
};

export const popularGoods = createSlice({
  name: "popularGoods",
  initialState,
  reducers: {
    setDataGetPopularGoodsBack(state, action) {
      state.dataGetPopularGoodsBack = action.payload;
      state.loadingGetPopularGoodsBack = false;
      state.errorGetPopularGoodsBack = null;
    },

    setErrorGetPopularGoodsBack(state, action) {
      state.errorGetPopularGoodsBack = action.payload;
      state.loadingGetPopularGoodsBack = false;
    },
    setLoadingGetPopularGoodsBack(state, action) {
      state.loadingGetPopularGoodsBack = action.payload;
      state.errorGetPopularGoodsBack = null;
    },
  },
});

export const {
  setLoadingGetPopularGoodsBack,
  setErrorGetPopularGoodsBack,
  setDataGetPopularGoodsBack,
} = popularGoods.actions;

export default popularGoods.reducer;
