import styled from "styled-components";

const StyledInput = styled.div`
  width: ${({ width }) => width?.xl};
  max-width: ${({ maxWidth }) => maxWidth?.xl};
  height: ${({ height }) => height?.xl};
  margin-left: ${({ marginLeft }) => marginLeft?.xl};
  margin-right: ${({ marginRight }) => marginRight?.xl};
  margin-top: ${({ marginTop }) => marginTop?.xl};
  font-size: ${({ fontSize }) => fontSize?.xl};
  @media (max-width: 1100px) {
    width: ${({ width }) => width?.lg};
    max-width: ${({ maxWidth }) => maxWidth?.lg};
    height: ${({ height }) => height?.lg};
    margin-top: ${({ marginTop }) => marginTop?.lg};
    margin-left: ${({ marginLeft }) => marginLeft?.lg};
    margin-right: ${({ marginRight }) => marginRight?.lg};
    font-size: ${({ fontSize }) => fontSize?.lg};
  }
  @media (max-width: 768px) {
    width: ${({ width }) => width?.md};
    max-width: ${({ maxWidth }) => maxWidth?.md};
    height: ${({ height }) => height?.md};
    margin-top: ${({ marginTop }) => marginTop?.md};
    margin-left: ${({ marginLeft }) => marginLeft?.md};
    margin-right: ${({ marginRight }) => marginRight?.md};
    font-size: ${({ fontSize }) => fontSize?.md};
  }
  @media (max-width: 580px) {
    width: ${({ width }) => width?.mdMinus};
    max-width: ${({ maxWidth }) => maxWidth?.mdMinus};
    height: ${({ height }) => height?.mdMinus};

    margin-top: ${({ marginTop }) => marginTop?.mdMinus};
    margin-left: ${({ marginLeft }) => marginLeft?.mdMinus};
    margin-right: ${({ marginRight }) => marginRight?.mdMinus};
    font-size: ${({ fontSize }) => fontSize?.mdMinus};
  }
  @media (max-width: 480px) {
    width: ${({ width }) => width?.smPlus};
    max-width: ${({ maxWidth }) => maxWidth?.smPlus};
    height: ${({ height }) => height?.smPlus};
    margin-top: ${({ marginTop }) => marginTop?.smPlus};
    margin-left: ${({ marginLeft }) => marginLeft?.smPlus};
    margin-right: ${({ marginRight }) => marginRight?.smPlus};
    font-size: ${({ fontSize }) => fontSize?.smPlus};
  }
  @media (max-width: 320px) {
    width: ${({ width }) => width?.sm};
    height: ${({ height }) => height?.sm};
    margin-top: ${({ marginTop }) => marginTop?.sm};
    margin-left: ${({ marginLeft }) => marginLeft?.sm};
    margin-right: ${({ marginRight }) => marginRight?.sm};
    font-size: ${({ fontSize }) => fontSize?.sm};
  }
`;

export default StyledInput;
