import {
  facebookIco,
  instagramIco,
  mailIco,
  markerIco,
  mobileIco,
  phoneIco,
  vodapnoneIco,
  youtubeIco,
} from "../ImageConstants/imageConstants";

export const positionCompanyOnMap = {
  lat: 50.444229015082556,
  lng: 30.44456931891537,
};

export const phoneNumbersData = [
  {
    id: 1,
    label: "+38 (044) 587 78 38",
    url: "tel:+380445877838",
    img: phoneIco,
    alt: "phone",
    tel: true,
  },
  {
    id: 2,
    label: "+38 (066) 203 14 89",
    url: "tel:+380662031489",
    img: vodapnoneIco,
    alt: "phone",
    tel: true,
  },
  {
    id: 3,
    label: "+38 (050) 341 50 21",
    url: "tel:+380503415021",
    img: vodapnoneIco,
    alt: "phone",
    tel: true,
  },
];

export const contactsPageData = [
  {
    id: 1,
    label: "commons.contacts.phones.phone_1",
    url: "tel:+380445877838",
    img: phoneIco,
    alt: "phone",
    tel: true,
  },
  {
    id: 2,
    label: "commons.contacts.phones.phone_2",
    url: "tel:+380662031489",
    img: mobileIco,
    alt: "phone",
    tel: true,
  },
  {
    id: 3,
    label: "commons.contacts.phones.phone_3",
    url: "tel:+380503415021",
    img: mobileIco,
    alt: "phone",
    tel: true,
  },
  {
    id: 4,
    label: "info@vectortool.ua",
    url: "mailto:info@vectortool.ua",
    img: mailIco,
    alt: "mail",
  },
  {
    id: 5,
    label: "commons.contacts.address",
    url: `https://www.google.com/maps/dir/?api=1&destination=${positionCompanyOnMap.lat},${positionCompanyOnMap.lng}`,
    img: markerIco,
    alt: "address",
    blank: true,
  },
];

export const socialsData = [
  {
    id: 1,
    url: "https://instagram.com/vectortool?igshid=3avfyy49n46x",
    img: instagramIco,
    alt: "instagram",
  },
  {
    id: 2,
    url: "https://www.facebook.com/VECTORTOOL.UA",
    img: facebookIco,
    alt: "facebook",
  },
  {
    id: 3,
    url: "https://www.youtube.com/channel/UC-MAq3-oM0DAybwAEEftRHg?view_as=subscriber",
    img: youtubeIco,
    alt: "youtube",
  },
];
