import React from "react";
import { useTranslation } from "react-i18next";

//styles
import styles from "./HeaderAddress.module.scss";

//images
import { markerIco } from "../../../ImageConstants/imageConstants";

export const HeaderAddress = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.address}>
      <img src={markerIco} alt="marker" />
      <span className={styles.label}>{t("commons.contacts.address")}</span>
    </div>
  );
};
