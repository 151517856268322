import { createSlice } from "@reduxjs/toolkit";

//api
import { putRequest } from "../../../API/api";

//routes
import {
  setModalWindowStatus,
  setModalWindowChildrenType,
  setModalWindowInfo,
} from "../../App/app";

import { getUserBack } from "../User";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

const token = localStorage.getItem("accessTokenVectortoll");

export const putUserChangeLegacyFormBack = (obj, id) => (dispatch) => {
  dispatch(setLoadingPutUserChangeLegacyFormBack(true));

  const success = () => {
    dispatch(getUserBack(token));
    dispatch(
      setModalWindowInfo({
        title: "modalWindows.success.change_IndividualForm.title",
        label: "modalWindows.success.change_IndividualForm.label",
      })
    );
    dispatch(setModalWindowChildrenType("success"));
    dispatch(setModalWindowStatus(true));
    dispatch(setLoadingPutUserChangeLegacyFormBack(false));
  };

  const setError = (error) => {
    dispatch(setErrorPutUserChangeLegacyFormBack(error?.response?.data));
    dispatch(backErrorModal(error));
  };

  putRequest({
    url: `/company-data/${id}`,
    success,
    setError,
    obj,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  dataPutUserChangeLegacyFormBack: null,
  loadingPutUserChangeLegacyFormBack: false,
  errorPutUserChangeLegacyFormBack: null,
};

export const userChangeLegacyForm = createSlice({
  name: "userChangeLegacyForm",
  initialState,
  reducers: {
    setDataPutUserChangeLegacyFormBack(state, action) {
      state.dataPutUserChangeLegacyFormBack = action.payload;
      state.loadingPutUserChangeLegacyFormBack = false;
      state.errorPutUserChangeLegacyFormBack = null;
    },

    setErrorPutUserChangeLegacyFormBack(state, action) {
      state.errorPutUserChangeLegacyFormBack = action.payload;
      state.loadingPutUserChangeLegacyFormBack = false;
    },
    setLoadingPutUserChangeLegacyFormBack(state, action) {
      state.loadingPutUserChangeLegacyFormBack = action.payload;
      state.errorPutUserChangeLegacyFormBack = null;
    },
  },
});

export const {
  setLoadingPutUserChangeLegacyFormBack,
  setErrorPutUserChangeLegacyFormBack,
  setDataPutUserChangeLegacyFormBack,
} = userChangeLegacyForm.actions;

export default userChangeLegacyForm.reducer;
