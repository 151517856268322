import { combineReducers, configureStore } from "@reduxjs/toolkit";

import app from "./App/app";

import seo from "./Seo/Seo";

import news from "./News/News";
import newsArticle from "./News/NewsArticle/NewsArticle";

import press from "./Press/Press";
import certificate from "./Certificate/Certificate";

import blog from "./Blog/Blog";
import blogArticle from "./Blog/BlogArticle/BlogArticle";

import videos from "./Videos/Videos";
import videosCategory from "./Videos/VideosCategory/VideosCategory";

//user
import user from "./User/User";
import userLogin from "./User/Login/Login";
import userLogout from "./User/Logout/Logout";
import userChangeLegacyForm from "./User/ChangeLegacyForm/ChangeLegacyForm";
import userChangeIndividualForm from "./User/ChangeIndividualForm/ChangeIndividualForm";
import userRegistration from "./User/Registration/Registration";
import userForgotPassword from "./User/ForgotPassword/ForgotPassword";

import header from "./App/Header/Header";

import basket from "./Basket/Basket";
import categories from "./Categories/Categories";
import category from "./Categories/Category/Category";

//goods
import goods from "./Goods/Goods";
import searchGoods from "./Goods/SearchGoods/SearchGoods";
import subCategoryGoods from "./Goods/SubCategoryGoods/SubCategoryGoods";
import popularGoods from "./Goods/PopularGoods/PopularGoods";

import good from "./Good/Good";

import order from "./Order/Order";

export const rootReducer = combineReducers({
  app: app,
  header,
  seo,
  news,
  newsArticle,
  press,
  certificate,
  blog,
  blogArticle,
  videos,
  videosCategory,
  //user
  user,
  userLogin,
  userLogout,
  userChangeLegacyForm,
  userChangeIndividualForm,
  userRegistration,
  userForgotPassword,

  basket,
  categories,
  category,
  //goods
  goods,
  searchGoods,
  subCategoryGoods,
  popularGoods,
  good,
  order,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
