import { createSlice } from "@reduxjs/toolkit";

//db
import { mainLanguageData } from "../../Db/languageData";

const initialState = {
  language: mainLanguageData?.key,
  isLocalizationUrl: "",
  modalWindowStatus: false,
  modalWindowChildrenType: "",
  modalWindowInfo: null,
  generalInfo: null,
  isAuth: localStorage.getItem("accessTokenVectortoll") || "",
  headerFocusMenu: false,
  isMobile: false,
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setIsLocalizationUrl: (state, action) => {
      state.isLocalizationUrl = action.payload;
    },
    setHeaderFocusMenu: (state, action) => {
      state.headerFocusMenu = action.payload;
    },
    setModalWindowChildrenType: (state, action) => {
      state.modalWindowChildrenType = action.payload;
    },
    setModalWindowStatus: (state, action) => {
      state.modalWindowStatus = action.payload;
    },
    setModalWindowInfo: (state, action) => {
      state.modalWindowInfo = action.payload;
    },
    setGeneralInfo: (state, action) => {
      state.generalInfo = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const {
  setLanguage,
  setIsLocalizationUrl,
  setHeaderFocusMenu,
  setLocalGenderUKR,
  setModalWindowStatus,
  setModalWindowChildrenType,
  setModalWindowInfo,
  setGeneralInfo,
  setToken,
  setIsMobile,
} = app.actions;

export default app.reducer;
