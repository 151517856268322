import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../../API/api";

//routes
import {
  setModalWindowStatus,
  setModalWindowChildrenType,
  setModalWindowInfo,
} from "../../App/app";

import { getUserBack } from "../User";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

const token = localStorage.getItem("accessTokenVectortoll");

export const postUserForgotPasswordBack = (obj) => (dispatch) => {
  dispatch(setLoadingPostUserForgotPasswordBack(true));

  const success = () => {
    dispatch(getUserBack(token));
    dispatch(
      setModalWindowInfo({
        title: "modalWindows.success.change_password.title",
        label: "modalWindows.success.change_password.label",
      })
    );
    dispatch(setModalWindowChildrenType("success"));
    dispatch(setModalWindowStatus(true));
  };

  const setError = (error) => {
    dispatch(setErrorPostUserForgotPasswordBack(error));
    dispatch(backErrorModal(error));
  };

  postRequest({
    url: `/forgot-password`,
    success,
    setError,
    obj,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  dataPostUserForgotPasswordBack: null,
  loadingPostUserForgotPasswordBack: false,
  errorPostUserForgotPasswordBack: null,
};

export const userForgotPassword = createSlice({
  name: "userForgotPassword",
  initialState,
  reducers: {
    setDataPostUserForgotPasswordBack(state, action) {
      state.dataPostUserForgotPasswordBack = action.payload;
      state.loadingPostUserForgotPasswordBack = false;
      state.errorPostUserForgotPasswordBack = null;
    },

    setErrorPostUserForgotPasswordBack(state, action) {
      state.errorPostUserForgotPasswordBack = action.payload;
      state.loadingPostUserForgotPasswordBack = false;
    },
    setLoadingPostUserForgotPasswordBack(state, action) {
      state.loadingPostUserForgotPasswordBack = action.payload;
      state.errorPostUserForgotPasswordBack = null;
    },
  },
});

export const {
  setLoadingPostUserForgotPasswordBack,
  setErrorPostUserForgotPasswordBack,
  setDataPostUserForgotPasswordBack,
} = userForgotPassword.actions;

export default userForgotPassword.reducer;
