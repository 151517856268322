import React from "react";
import { useDispatch } from "react-redux";

//styles
import styles from "./GoodSlider.module.scss";

//images
import { zoomIco } from "../../../ImageConstants/imageConstants";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../../../ReduxToolkit/App/app";

//api
import { baseURLSingle } from "../../../API/api";

const SliderItem = ({ images, slide }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {images
        .filter((el) => el.id === slide)
        .map(({ id = 1, image_url, alt }) => (
          <div key={id} className={styles.slide}>
            <picture>
              <source
                srcSet={image_url.webp && `${baseURLSingle}/${encodeURI(image_url.webp.trim())}`}
                type="image/webp"
              />
              <img
                className={styles.slide__img}
                src={image_url.src && `${baseURLSingle}/${image_url.src}`}
                alt={alt}
              />
            </picture>
            <button
              className={styles.slide__btn}
              onClick={() => {
                dispatch(
                  setModalWindowInfo({
                    image: image_url,
                    images: images,
                    alt: alt,
                    id: slide,
                  })
                );
                dispatch(setModalWindowChildrenType("zoom"));
                dispatch(setModalWindowStatus(true));
              }}
            >
              <img src={zoomIco} alt="zoom" />
            </button>
          </div>
        ))}
    </div>
  );
};

export default SliderItem;

// const SliderItem = ({ slide }) => {
//   const dispatch = useDispatch();

//   return (
//     <div className={styles.slide}>
//       <img
//         className={styles.slide__img}
//         src={`${baseURLSingle}/${slide.image_url}`}
//         alt={slide.id}
//       />

//       <button
//         className={styles.slide__btn}
//         onClick={() => {
//           dispatch(
//             setZoomImageObject({
//               image: slide.image_url,
//             })
//           );
//           dispatch(setModalWindowChildrenType("zoom"));
//           dispatch(setModalWindowStatus(true));
//         }}
//       >
//         <img src={zoomIco} alt="zoom" />
//       </button>
//     </div>
//   );
// };

// export default SliderItem;
