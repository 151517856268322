import * as Yup from "yup";
import { passwordRegExp } from "../../../../Utils/formRegExp";

export const RegisterFormSchema = (t) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("yupValidation.email"))
      .required(t("yupValidation.isRequired")),
    password: Yup.string()
      .required(t("yupValidation.isRequired"))
      .matches(
        passwordRegExp,
        `${t(`yupValidation.minSymbols`)} 6 ${t(`yupValidation.symbols`)}`
      ),
    password_confirmation: Yup.string()
      .required(t("yupValidation.isRequired"))
      .matches(
        passwordRegExp,
        `${t(`yupValidation.minSymbols`)} 6 ${t(`yupValidation.symbols`)}`
      ),
    name: Yup.string().required(t("yupValidation.isRequired")),
  });
