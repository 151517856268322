import AuthModal from "./AuthModal";
import ConsultModal from "./ConsultModal";
import RegisterModal from "./RegisterModal";
import ZoomImageModal from "./ZoomImageModal";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import ProductModal from "./ProductModal";
import CertificateModal from "./CertificateModal";
import PaytmentModal from "./PaytmentModal";
import RequestPriceModal from "./RequestPriceModal";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import PressImageModal from "./PressImageModal";

export const selectModal = (type) => {
  switch (type) {
    case "auth":
      return <AuthModal />;
    case "consult":
      return <ConsultModal />;
    case "register":
      return <RegisterModal />;
    case "success":
      return <SuccessModal />;
    case "error":
      return <ErrorModal />;
    case "product":
      return <ProductModal />;
    case "zoom":
      return <ZoomImageModal />;
    case "payment":
      return <PaytmentModal />;
    case "certificate":
      return <CertificateModal />;
    case "requestPrice":
      return <RequestPriceModal />;
    case "forgotPassword":
      return <ForgotPassword />;
    case "press":
      return <PressImageModal />;
    default:
      throw new Error("No one modals type didn`t select");
  }
};
