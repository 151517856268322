import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink } from "react-router-dom";

//styles
import styles from "./ConsultModal.module.scss";

//redux
import { setModalWindowChildrenType, setModalWindowStatus } from "../../../../ReduxToolkit/App/app";

//commons
import ModalTitle from "../ModalTitle";
import Input from "../../Inputs/Input/Input";
import { ConsultFormSchema } from "./ConsultFormSchema";

//api
import { createLead } from "../../../../API/createBitrixLead";

//app
import { PRIVACY_PAGE_ROUTE, pathFC } from "../../../App/Routes/routesConstants";

const nameFields = {
  name: "name",
  phone: "phone",
};

export const ConsultModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(ConsultFormSchema(t)),
  });

  const onSubmit = (data) => {
    const obj = {
      name: data.name,
      phone: data.phone,
    };
    const type = "consult";
    createLead(obj, type, dispatch);

    window.dataLayer.push({'event': 'contact'});
  };

  return (
    <div className={styles.modal}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle title={t("modalWindows.consult.title")} />
        <div className={styles.label}>{t("modalWindows.consult.label")}</div>
        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.name}
          name={nameFields.name}
          type="text"
          placeholder={t("modalWindows.consult.inputs.name")}
          required
          error={errors?.name}
          register={register}
        />
        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.phone}
          name={nameFields.phone}
          type="text"
          placeholder={t("modalWindows.consult.inputs.phone")}
          required
          error={errors?.phone}
          register={register}
        />
        <button className={styles.btn} type="submit">
          {t("modalWindows.consult.button")}
        </button>
        <div className={styles.privacy_info}>
          {t("forms.privacy1")}
          {" "}
          <NavLink
            onClick={() => {
              dispatch(setModalWindowStatus(false));
              dispatch(setModalWindowChildrenType(""));
            }}
            to={pathFC(`${PRIVACY_PAGE_ROUTE}`)}
          >
            {t("forms.privacy2")}
          </NavLink>
        </div>
      </form>
    </div>
  );
};
