import React from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./SwitchLanguage.module.scss";

//redux
import { setLanguage } from "../../../ReduxToolkit/App/app";

//redux
import { languageData } from "../../../Db/languageData";

export const SwitchLanguage = () => {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);

  const changeLanguage = (language) => {
    localStorage.setItem("language_vectortool", language);
    dispatch(setLanguage(language));
    window.location.reload();
    // setTimeout(() => {
    //   window.location.reload();
    // });
  };

  return (
    <div className={styles.wrapper}>
      {languageData.map(({ key = "", label = "" }) => (
        <LanguageBtn
          key={key}
          keyLang={key}
          label={label}
          lang={language}
          changeLanguage={changeLanguage}
        />
      ))}
    </div>
  );
};

const LanguageBtn = ({ keyLang, label, lang, changeLanguage }) => {
  return (
    <div
      className={`${styles.language} ${lang === keyLang && styles.active}`}
      onClick={() => changeLanguage(keyLang)}
    >
      {label}
    </div>
  );
};
