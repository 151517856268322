import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

//styles
import styles from "./RequestPriceModal.module.scss";

//redux
import { setModalWindowChildrenType, setModalWindowStatus } from "../../../../ReduxToolkit/App/app";

//commons
import Input from "../../Inputs/Input/Input";
import { ConsultFormSchema } from "./ConsultFormSchema";

//api
import { createLead } from "../../../../API/createBitrixLead";
import { baseURLSingle } from "../../../../API/api";

//app
import { PRIVACY_PAGE_ROUTE, pathFC } from "../../../App/Routes/routesConstants";

const nameFields = {
  name: "name",
  phone: "phone",
};

export const RequestPriceModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const goodData = useSelector((state) => state.goods.good);

  const product = useSelector((state) => state.app.modalWindowInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(ConsultFormSchema(t)),
  });

  const onSubmit = (data) => {
    const obj = {
      name: data.name,
      phone: data.phone,
      productName: goodData?.data?.name || product?.name,
    };
    const type = "requestPrice";
    createLead(obj, type, dispatch);

    window.dataLayer.push({'event': 'form_success'});
  };

  return (
    <div className={styles.modal}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.content}>
          <picture>
            <source
              srcSet={product?.images?.[0]?.image_url.webp && `${baseURLSingle}/${encodeURI(product?.images?.[0]?.image_url.webp.trim())}`}
              type="image/webp"
            />
            <img
              src={product?.images?.[0]?.image_url.src && `${baseURLSingle}/${product?.images?.[0]?.image_url.src}`}
              alt={goodData?.data?.name || product.name || " "}
            />
          </picture>
          <div>
            <p className={styles.productName}>
              {goodData?.data?.name || product.name}
            </p>
            <p className={styles.label}>{t("modalWindows.consult.label")}</p>
          </div>
        </div>

        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.name}
          name={nameFields.name}
          type="text"
          placeholder={t("modalWindows.consult.inputs.name")}
          required
          error={errors?.name}
          register={register}
        />
        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.phone}
          name={nameFields.phone}
          type="text"
          placeholder={t("modalWindows.consult.inputs.phone")}
          required
          error={errors?.phone}
          register={register}
        />

        <button className={styles.btn} type="submit">
          {t("modalWindows.consult.button")}
        </button>

        <div className={styles.privacy_info}>
          {t("forms.privacy1")}
          {" "}
          <NavLink
            onClick={() => {
              dispatch(setModalWindowStatus(false));
              dispatch(setModalWindowChildrenType(""));
            }}
            to={pathFC(`${PRIVACY_PAGE_ROUTE}`)}
          >
            {t("forms.privacy2")}
          </NavLink>
        </div>
      </form>
    </div>
  );
};
