import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getNewsBack = (params) => (dispatch) => {
  dispatch(setLoadingGetNewsBack(true));

  const success = (data) => {
    dispatch(
      setDataGetNewsBack({
        data: data?.data,
        meta: data?.meta,
      })
    );
  };

  const setError = (error) => {
    dispatch(setErrorGetNewsBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/news`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetNewsBack: null,
  metaGetNewsBack: null,
  loadingGetNewsBack: false,
  errorGetNewsBack: null,
};

export const news = createSlice({
  name: "news",
  initialState,
  reducers: {
    setDataGetNewsBack(state, action) {
      state.dataGetNewsBack = action.payload.data;
      state.metaGetNewsBack = action.payload.meta;
      state.loadingGetNewsBack = false;
      state.errorGetNewsBack = null;
    },

    setErrorGetNewsBack(state, action) {
      state.errorGetNewsBack = action.payload;
      state.loadingGetNewsBack = false;
    },
    setLoadingGetNewsBack(state, action) {
      state.loadingGetNewsBack = action.payload;
      state.errorGetNewsBack = null;
    },
  },
});

export const {
  setLoadingGetNewsBack,
  setErrorGetNewsBack,
  setDataGetNewsBack,
} = news.actions;

export default news.reducer;
