export function createPages(pages, pagesCount, currentPage, images) {
  if (pagesCount > 3) {
    if (currentPage > 2) {
      for (let i = currentPage - 2; i < currentPage + 1; i++) {
        images[i] && pages.push(images[i]);
        if (i === pagesCount) break;
      }
    } else {
      for (let i = 0; i < 3; i++) {
        images[i] && pages.push(images[i]);
        if (i === pagesCount) break;
      }
    }
  } else {
    for (let i = 0; i <= pagesCount; i++) {
      images[i] && pages.push(images[i]);
    }
  }
  return pages;
}
