import React from "react";
import { useTranslation } from "react-i18next";

//styles
import styles from "./Title.module.scss";

export const Title = ({ label = "", whiteColor = false, h1 = true }) => {
  const { t } = useTranslation();
  return (
    h1 ? (
      <h1 className={`${styles.title} ${whiteColor && styles.white}`}>
        {t(label)}
      </h1>
    ) : (
      <h2 className={`${styles.title} ${whiteColor && styles.white}`}>
        {t(label)}
      </h2>
    )
  );
};
