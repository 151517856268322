import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
//styles
import styles from "./HeaderProductMenu.module.scss";

//app
import { PRODUCTS_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

//api
import { baseURLSingle } from "../../../API/api";

const HeaderProductSubmenu = ({ focusMenu, setFocus, productMenu }) => {
  const { t } = useTranslation();

  return (
    <ul className={`${styles.menu__list} ${focusMenu && styles.active}`}>
      {productMenu.map(
        ({ id = 1, image = "", slug = "/", name = "", children = [] }) => {
          const slugParent = slug;
          return (
            <li key={id} className={styles.menu__item}>
              <LazyLoadComponent>
                <picture>
                  <source
                    srcSet={image.webp && `${baseURLSingle}/${encodeURI(image.webp.trim())}`}
                    type="image/webp"
                  />
                  <img
                    className={styles.menu__img}
                    src={image.src && `${baseURLSingle}/${image.src}`}
                    alt="product"
                  />
                </picture>
              </LazyLoadComponent>
              <div>
                <NavLink
                  to={pathFC(`${PRODUCTS_PAGE_ROUTE}/${slug}`)}
                  className={styles.menu__title}
                  onClick={() => {
                    if (focusMenu) setFocus();
                  }}
                >
                  {t(name)}
                </NavLink>
                <ul className={styles.submenu}>
                  {children.map(({ id = 1, slug = "/", name = "" }) => (
                    <li
                      onClick={() => {
                        if (focusMenu) setFocus();
                      }}
                      className={styles.submenu__item}
                      key={id}
                    >
                      <NavLink
                        to={pathFC(
                          `${PRODUCTS_PAGE_ROUTE}/${slugParent}/${slug}`
                        )}
                        className={styles.submenu__link}
                      >
                        {t(name)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default HeaderProductSubmenu;
