import React from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./Header.module.scss";

//images
import { headerLogoImg } from "../../ImageConstants/imageConstants";

//app

//commons
import CartButton from "../Commons/CartButton";
import Logo from "../Commons/Logo";
import ProfileButton from "../Commons/ProfileButton";
import SearchBar from "../Commons/SearchBar";
import SwitchLanguage from "../Commons/SwitchLanguage";

//components
import HeaderAddress from "./HeaderAddress";
import HeaderContacts from "./HeaderContacts";
import HeaderConsultation from "./HeaderConsultation";
import HeaderNavigation from "./HeaderNavigation";
import HeaderProductBtn from "./HeaderProductBtn";
import HeaderProductMenu from "./HeaderProductMenu";

//redux
import { setHeaderFocusMenu } from "../../ReduxToolkit/App/app";

//utils
import useScrollBlock from "../../Utils/useScrollBlock";

const Header = () => {
  const dispatch = useDispatch();

  const [blockScroll, allowScroll] = useScrollBlock();

  const focusMenu = useSelector((state) => state.app.headerFocusMenu);

  const setFocus = () => {
    dispatch(setHeaderFocusMenu(!focusMenu));

    if (!focusMenu) blockScroll();

    if (focusMenu) allowScroll();
  };

  return (
    <header className={styles.header}>
      <section className={`${styles.top} container`}>
        <Logo
          img={headerLogoImg}
          setFocusMenu={setFocus}
          focusMenu={focusMenu}
        />
        <SearchBar />
        <HeaderContacts />
        <HeaderAddress />
        <SwitchLanguage />
        <div className={styles.top__right}>
          <ProfileButton />
          <CartButton />
        </div>
      </section>
      <section className={styles.bottom}>
        <div className={`${styles.bottom__wrapper} container`}>
          <HeaderProductBtn focus={focusMenu} setFocus={setFocus} />
          <HeaderNavigation focusMenu={focusMenu} setFocusMenu={setFocus} />
          <HeaderConsultation />
        </div>
        <HeaderProductMenu
          blockScroll={blockScroll}
          focus={focusMenu}
          setFocus={setFocus}
        />
      </section>
    </header>
  );
};

export default Header;
