import React from "react";
import { useSelector } from "react-redux";

//styles
import styles from "./CertificateModal.module.scss";

//utils
import { checkImagePath } from "../../../../Utils/checkImagePath";

export const CertificateModal = () => {
  const certificate = useSelector((state) => state.app.modalWindowInfo);

  const { image = "" } = certificate;

  return (
    <div className={styles.modal}>
      <div className={styles.image}>
        <picture>
          <source
            srcSet={image.webp && checkImagePath(image.webp)}
            type="image/webp"
          />
          <img
            className={styles.slide__img+''}
            src={image.src && checkImagePath(image.src)}
            alt=''
          />
        </picture>
      </div>
    </div>
  );
};
