import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../../API/api";

import {
  setModalWindowStatus,
  setModalWindowChildrenType,
  setModalWindowInfo,
} from "../../App/app";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

export const postUserRegistrationBack = (obj) => (dispatch) => {
  dispatch(setLoadingPostUserRegistrationBack(true));

  const success = (data) => {
    dispatch(setDataPostUserRegistrationBack(data));
    dispatch(
      setModalWindowInfo({
        title: "modalWindows.success.register.title",
        label: "modalWindows.success.register.label",
      })
    );
    dispatch(setModalWindowChildrenType("success"));
    dispatch(setModalWindowStatus(true));
  };

  const setError = (error) => {
    dispatch(setErrorPostUserRegistrationBack(error?.response?.data));
    dispatch(backErrorModal(error));
  };

  postRequest({
    url: "/auth/register",
    success,
    setError,
    obj,
  });
};

const initialState = {
  dataPostUserRegistrationBack: null,
  loadingPostUserRegistrationBack: false,
  errorPostUserRegistrationBack: null,
};

export const userRegistration = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    setDataPostUserRegistrationBack(state, action) {
      state.dataPostUserRegistrationBack = action.payload;
      state.loadingPostUserRegistrationBack = false;
      state.errorPostUserRegistrationBack = null;
    },

    setErrorPostUserRegistrationBack(state, action) {
      state.errorPostUserRegistrationBack = action.payload;
      state.loadingPostUserRegistrationBack = false;
    },
    setLoadingPostUserRegistrationBack(state, action) {
      state.loadingPostUserRegistrationBack = action.payload;
      state.errorPostUserRegistrationBack = null;
    },
  },
});

export const {
  setLoadingPostUserRegistrationBack,
  setErrorPostUserRegistrationBack,
  setDataPostUserRegistrationBack,
} = userRegistration.actions;

export default userRegistration.reducer;
