import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import "../../Styles/styles.scss";
import "./App";
import "swiper/swiper.scss";

import "swiper/modules/free-mode/free-mode.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/thumbs/thumbs.scss";
import "swiper/modules/lazy/lazy.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//commons
import ModalWindow from "../Commons/ModalWindow";
import { selectModal } from "../Commons/ModalWindow/selectModal";
import ArrowToTop from "../Commons/ArrowToTop";

//components
import Header from "../Header";
import Footer from "../Footer";
import AppRouter from "./AppRouter";
import HeaderMobile from "../HeaderMobile";

//redux
import { setLanguage } from "../../ReduxToolkit/App/app";
import { setIsMobile } from "../../ReduxToolkit/App/app";
import { getUserBack } from "../../ReduxToolkit/User/User";
import { getCategoriesBack } from "../../ReduxToolkit/Categories/Categories";
import { getBasketGoodsBack } from "../../ReduxToolkit/Basket/Basket";

//utils
import { findUrlLocalization } from "../../Utils/language/findUrlLocalization";
import { languageInitialization } from "../../Utils/language/languageInitialization";

//db
import { mainLanguageData } from "../../Db/languageData";

export const App = () => {
  const dispatch = useDispatch();

  const isMobile = useSelector((state) => state.app.isMobile);
  dispatch(setIsMobile(useMediaQuery({ maxWidth: 575 })));

  const token = localStorage.getItem("accessTokenVectortoll");

  const [topOffset, setTopOffset] = useState(0);

  const { modalWindowStatus, modalWindowChildrenType } = useSelector(
    (state) => state.app
  );

  const [, i18n] = useTranslation();

  const location = useLocation();

  const navigate = useHistory();

  const language = useSelector((state) => state.app.language);

  const params = { lang: language };

  useEffect(() => {
    dispatch(getBasketGoodsBack({ lang: language }));
  }, [language]);

  const observeScrollPage = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 800 && window.scrollY < 950) {
        setTopOffset(801);
      }
      if (window.scrollY < 800 && topOffset !== 0) {
        setTopOffset(0);
      }
      if (topOffset === 0) return;
    });
  };

  observeScrollPage();

  useEffect(() => {
    window.onpopstate = function(event) {
      const language = localStorage.getItem("language_vectortool");
      if(((language == 'uk') && window.location.pathname.startsWith('/ru/')) ||
         ((language == 'ru') && !window.location.pathname.startsWith('/ru/'))) {
        setIsReadyRender(false);
        window.location.reload();
      }
    };

    detectLanguage();
  }, []);

  const pathSplit = location.pathname.split("/");
  const isLocalizationUrl = findUrlLocalization(pathSplit);

  const [isReadyRender, setIsReadyRender] = useState(false);
  const [languageLocal, setIsLanguageLocal] = useState(false);

  const detectLanguage = () => {
    const language = localStorage.getItem("language_vectortool");
    changeLanguageApp(language || isLocalizationUrl || mainLanguageData?.key);
  };

  const changeLanguageApp = (language) => {
    dispatch(setLanguage(language));
    i18n.changeLanguage(language);
    const ready = languageInitialization(language, location, navigate);

    setIsLanguageLocal(language ? language : mainLanguageData?.key);
    document.documentElement.lang = language ? language : mainLanguageData?.key;

    setIsReadyRender(() => ready);
  };

  useEffect(() => {
    if (isReadyRender) {
      dispatch(getCategoriesBack(params));
      token && dispatch(getUserBack(token, params));
    }
  }, [isReadyRender]);

  if (!isReadyRender) return <></>;

  return (
    <>
      {modalWindowStatus && (
        <ModalWindow>{selectModal(modalWindowChildrenType)}</ModalWindow>
      )}
      {isMobile ? <HeaderMobile /> : <Header />}
      <div className="header__fake"></div>
      <AppRouter />
      <Footer />
      {topOffset >= 800 && <ArrowToTop />}
    </>
  );
};