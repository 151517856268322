import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//styles
import styles from "./GoodSlider.module.scss";
import "./GoodSlider.scss";

//images
import pause from "../../../Assets/Icons/Products/pause.png";
import threeD from "../../../Assets/Icons/Products/3d.png";

//components
import SliderItem from "./SliderItem";
import SliderDots from "./SliderDots";

export const GoodSlider = ({ good, modal }) => {
  const { t } = useTranslation();

  const [indexSlide, setIndexSlide] = useState(0);

  const { images, video, model_3d } = good;

  const [slide, setSlide] = useState(images[0]?.id || 1);

  return (
    <>
      {images.length > 0 && (
        <div className={styles.innerImgSlider}>
          <div className={modal ? styles.sliderModal : styles.slider}>
            <SliderDots
              images={images}
              slide={slide}
              setSlide={setSlide}
              setIndexSlide={setIndexSlide}
              indexSlide={indexSlide}
            />
            <SliderItem images={images} slide={slide} />
          </div>
          {!modal && (model_3d || video) && (
            <div className={styles.opportunities}>
              {video && (
                <a
                  href={video}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btnPause}
                >
                  <img src={pause} alt="pause" className={styles.icon} />
                  {t("goodSlider.watchVideo")}
                </a>
              )}
              {model_3d && (
                <a
                  href={model_3d}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.btnThreeD}
                >
                  <img src={threeD} alt="threeD" className={styles.icon} />
                  {t("goodSlider.watch3D")}
                </a>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

// import React, { useState, useEffect, useRef } from "react";
// import { useTranslation } from "react-i18next";
// import { useMediaQuery } from "react-responsive";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// //styles
// import styles from "./GoodSlider.module.scss";
// import "./GoodSlider.scss";

// //images
// import pause from "../../../Assets/Icons/Products/pause.png";
// import threeD from "../../../Assets/Icons/Products/3d.png";

// //components
// import SliderItem from "./SliderItem";

// //api
// import { baseURLSingle } from "../../../API/api";

// export const GoodSlider = ({ good, modal }) => {
//   const { t } = useTranslation();

//   const { images, video, model_3d } = good;

//   const isTablet = useMediaQuery({ maxWidth: 1100 });

//   const [nav1, setNav1] = useState(null);
//   const [nav2, setNav2] = useState(null);

//   const slider1 = useRef(null);
//   const slider2 = useRef(null);

//   useEffect(() => {
//     setNav1(slider1.current);
//     setNav2(slider2.current);
//   }, [good]);

//   function Arrow(props) {
//     let className = props.type === "next" ? styles.nextArrow : styles.prevArrow;
//     return (
//       <div className={className} onClick={props.onClick}>
//         <svg
//           width="20"
//           height="15"
//           viewBox="0 0 12 9"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             fillRule="evenodd"
//             clipRule="evenodd"
//             d="M12 0.632813L9.53846 0.632812L6 4.63281L2.46154 0.632812L0 0.632812L6 7.2482L12 0.632813Z"
//             fill="#C4C4C4"
//           />
//         </svg>
//       </div>
//     );
//   }
//   const settingsSlider1 = {
//     swipeToSlide: true,
//     focusOnSelect: true,
//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     nextArrow: <Arrow type="next" />,
//     prevArrow: <Arrow type="prev" />,
//     vertical: isTablet ? false : true,
//   };

//   return (
//     <>
//       <div className={modal ? styles.sliderModal : styles.slider}>
//         <Slider
//           asNavFor={nav1}
//           ref={slider2}
//           className={`${
//             modal
//               ? images.length > 1 && styles.dotsModal
//               : images.length > 1 && styles.dots
//           } slider-dots`}
//           {...settingsSlider1}
//         >
//           {images.length > 1 &&
//             images?.map((slide) => {
//               return (
//                 <div className={styles.dot} key={slide.id}>
//                   <img
//                     className={styles.dot_img}
//                     src={
//                       slide.image_url && `${baseURLSingle}/${slide.image_url}`
//                     }
//                     alt="hard"
//                   />
//                 </div>
//               );
//             })}
//         </Slider>

//         <div className={styles.innerImgSlider}>
//           <Slider
//             asNavFor={nav2}
//             ref={slider1}
//             arrows={false}
//             className={`${
//               modal
//                 ? images.length > 0 && styles.slideModal
//                 : images.length > 0 && styles.slide
//             }`}
//           >
//             {images?.map((slide, index) => {
//               return <SliderItem slide={slide} index={index} key={index} />;
//             })}
//           </Slider>
//           {!modal && (
//             <div className={styles.opportunities}>
//               {video && (
//                 <a
//                   href={video}
//                   target="_blank"
//                   rel="noreferrer"
//                   className={styles.btnPause}
//                 >
//                   <img src={pause} alt="pause" className={styles.icon} />
//                   {t("goodSlider.watchVideo")}
//                 </a>
//               )}
//               {model_3d && (
//                 <a
//                   href={model_3d}
//                   target="_blank"
//                   rel="noreferrer"
//                   className={styles.btnThreeD}
//                 >
//                   <img src={threeD} alt="threeD" className={styles.icon} />
//                   {t("goodSlider.watch3D")}
//                 </a>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// ? images.length > 0 && styles.slideModal
//                 : images.length > 0 && styles.slide
