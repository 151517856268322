import React from "react";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
//styles
import style from "./Logo.module.scss";

//app
import { MAIN_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

export const Logo = ({ img = "", setFocusMenu, focusMenu, footer }) => {
  return (
    <NavLink
      to={pathFC(MAIN_PAGE_ROUTE)}
      onClick={() => {
        if (focusMenu) setFocusMenu();
        window.scrollTo(0, 0);
      }}
    >
      {footer ?
      <LazyLoadImage
        className={style.logo}
        src={img}
        alt="logo" />
      :
      <img className={style.logo} src={img} alt="logo" />
      }
    </NavLink>
  );
};
