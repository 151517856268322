import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//styles
import styles from "./HeaderConsultation.module.scss";

//images
import { arrowRightIco } from "../../../ImageConstants/imageConstants";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../ReduxToolkit/App/app";

export const HeaderConsultation = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isFocus, setFocus] = useState(false);

  const openModalWindow = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(setModalWindowStatus(true));
      dispatch(setModalWindowChildrenType("consult"));
    }
  };

  return (
    <div
      className={styles.link}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
      onClick={openModalWindow}
    >
      {t("header.consultation")}
      <img
        src={arrowRightIco}
        className={`${styles.img} ${isFocus ? styles.active : ""}`}
        alt="arrow"
      />
    </div>
  );
};
