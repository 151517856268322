import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./SuccessModal.module.scss";

//commons
import Title from "../../Title";

//redux
import { setModalWindowStatus } from "../../../../ReduxToolkit/App/app";

export const SuccessModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const successModalObject = useSelector((state) => state.app.modalWindowInfo);

  const { title = "", label = "" } = successModalObject;

  return (
    <div className={styles.modal}>
      <Title label={title} />
      <div className={styles.text}>{t(label)}</div>
      <button
        className={styles.btn}
        onClick={() => dispatch(setModalWindowStatus(false))}
      >
        {t("modalWindows.success.button")}
      </button>
    </div>
  );
};
