import {
  setModalWindowChildrenType,
  setModalWindowStatus,
  setModalWindowInfo,
} from "../ReduxToolkit/App/app";

export const backErrorModal = (error) => (dispatch) => {
  const message = error?.response?.data?.message;
  if (typeof message === "string" && message !== "") {
    dispatch(setModalWindowChildrenType("error"));
    dispatch(
      setModalWindowInfo({
        title: "modalWindows.success.notAddGoodInBasket.title",
        label: message,
      })
    );
    dispatch(setModalWindowStatus(true));
  }
};
