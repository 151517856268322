import axios from "axios";
import { addLead, addGoodsLead } from "./bitrixApiConst";

import {
  setModalWindowStatus,
  setModalWindowChildrenType,
  setModalWindowInfo,
} from "../ReduxToolkit/App/app";

export const createLead = async (userInfo, type, dispatch) => {
  const date = new Date();

  const types = {
    consult: "отримання консультації",
    order: "на замовлення",
    mailing: "підписка на розсилку",
    requestPrice: "дізнатися вартість товару",
  };

  let description = "";
  if (userInfo.textarea) {
    description = `Коментар: ${userInfo.textarea} Ссылка на СV: ${userInfo.link}`;
  } else if (userInfo.link) {
    description = `Ссылка на СV: ${userInfo.link}`;
  } else if (userInfo.productName) {
    description = `Назва товару: ${userInfo.productName}`;
  }

  let fields = {
    TITLE: types[type],
    DATE_CREATE: date,
    OPENED: "Y",
    SOURCE_DESCRIPTION: description,
    SOURCE_ID: "STORE",
  };

  if (type === "mailing") {
    fields.EMAIL = [
      {
        VALUE: userInfo.email,
      },
    ];
  }

  if (type === "consult" || type === "requestPrice") {
    fields.PHONE = [
      {
        VALUE: userInfo.phone,
      },
    ];
    fields.NAME = userInfo.name;
  }

  if (type === "order") {
    fields.CURRENCY_ID = "UAH";
    fields.OPPORTUNITY = userInfo.delivery_price;

    fields.PHONE = [
      {
        VALUE: userInfo.bill_to_phone,
      },
    ];
    fields.EMAIL = [
      {
        VALUE: userInfo.bill_to_email,
      },
    ];

    if (userInfo.bill_to_name) {
      const PIB = userInfo.bill_to_name.split(" ", 3);
      if (PIB[0]) {
        fields.NAME = PIB[0];
      }
      if (PIB[1]) {
        fields.LAST_NAME = PIB[1];
      }
      if (PIB[2]) {
        fields.SECOND_NAME = PIB[2];
      }
    }
    if (userInfo.order) {
      fields.COMMENTS = userInfo.order;
    }
    if (userInfo.address) {
      fields.ADDRESS = userInfo.address;
    }
    if (userInfo.address) {
      fields.ADDRESS = userInfo.address;
    }
    if (userInfo.post_department) {
      fields.ADDRESS = userInfo.post_department;
    }
  }

  axios
    .post(`${addLead}`, { fields })
    .then((response) => {
      if (type !== "order") {
        let title = null;
        let label = null;
        if (type === "consult") {
          title = "modalWindows.success.consult.title";
          label = "modalWindows.success.consult.label";
        } else {
          title = "modalWindows.success.mailing.title";
          label = "modalWindows.success.mailing.label";
        }
        if (type === "requestPrice") {
          title = "modalWindows.success.succesRequestPrice.title";
          label = "modalWindows.success.succesRequestPrice.label";
        }

        dispatch(
          setModalWindowInfo({
            title: title,
            label: label,
          })
        );
        dispatch(setModalWindowChildrenType("success"));
        dispatch(setModalWindowStatus(true));
      } else {
        sendGoodsLead(response.data.result);
      }

      return response.data.result;
    })
    .catch((error) => {
      dispatch(
        setModalWindowInfo({
          title: error,
        })
      );
      dispatch(setModalWindowChildrenType("success"));
      dispatch(setModalWindowStatus(true));
      return error;
    });

  const sendGoodsLead = (id) => {
    const goods = userInfo.order.map((good) => {
      let price = 0;
      good?.variations?.length > 0
        ? good.variations?.map((variation) => {
            if (variation.id === good.variant_id) {
              price = variation.price;
            }
          })
        : (price = good.price);
      if (good.variant_id === good.product_id) {
        price = good.price;
      }
      return {
        PRODUCT_ID: good.product_id,
        PRODUCT_NAME: good?.products?.name?.ru,
        PRICE: price,
        QUANTITY: good.quantity,
      };
    });

    axios
      .post(`${addGoodsLead}`, {
        id: id,
        rows: goods,
      });
  };
};
