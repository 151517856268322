import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

//styles
import styles from "./HeaderMobile.module.scss";

//images
import { headerLogoImg } from "../../ImageConstants/imageConstants";
import { searchMobileIco } from "../../ImageConstants/imageConstants";

//commons
import BurgerButton from "../Commons/BurgerButton";
import Logo from "../Commons/Logo";
import CartButton from "../Commons/CartButton";

//components
import HeaderContacts from "../Header/HeaderContacts";
import HeaderConsultation from "../Header/HeaderConsultation";
import HeaderMobileMenu from "./HeaderMobileMenu";

//redux
import { setFocusBurger } from "../../ReduxToolkit/App/Header/Header";

//utils
import useScrollBlock from "../../Utils/useScrollBlock";

//app
import { SEARCH_PAGE_ROUTE, pathFC } from "../App/Routes/routesConstants";

export const HeaderMobile = () => {
  const dispatch = useDispatch();

  const [blockScroll, allowScroll] = useScrollBlock();

  const focus = useSelector((state) => state.header.focusBurger);
  const setFocus = () => {
    dispatch(setFocusBurger(!focus));
    if (!focus) blockScroll();

    if (focus) allowScroll();
  };

  const closeBurger = () => {
    dispatch(setFocusBurger(false));
  };

  return (
    <header className={styles.header_mobile}>
      <section className={styles.top}>
        <BurgerButton focus={focus} setFocus={setFocus} />
        <div
          onClick={() => {
            closeBurger();
          }}
        >
          <Logo img={headerLogoImg} />
        </div>
        <div
          onClick={() => {
            closeBurger();
          }}
        >
          <NavLink className={styles.search} to={pathFC(`${SEARCH_PAGE_ROUTE}`)}>
            <img src={searchMobileIco} alt="search" />
          </NavLink>
          <CartButton count={0} />
        </div>
      </section>
      <section className={styles.bottom}>
        <HeaderContacts />
        <HeaderConsultation />
      </section>
      {focus && <HeaderMobileMenu focus={focus} closeMenu={setFocus} />}
    </header>
  );
};
