export const IncludeMassElement = (checked, isChecked, setIsChecked) => {
  if (!isChecked.includes(checked)) {
    setIsChecked(() => [...isChecked, checked]);
  } else {
    let index = isChecked.findIndex((item) => item === checked);
    setIsChecked(() => [
      ...isChecked.slice(0, index),
      ...isChecked.slice(index + 1),
    ]);
  }
  return { isChecked };
};
