import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

//styles
import styles from "./GoodTable.module.scss";

//components
import Counter from "../Counter";

//utils
import { spliter } from "../../../Utils/spliter";

export const GoodTable = ({
  array,
  priceGood,
  idGood,
  specificationsGood,
  setMaxPrice,
  setMinPrice,
  basketGoodVariations,
  setBasketGoodVariations,
  currency,
}) => {
  const { t } = useTranslation();

  const priceHead = t("goodTable.priceHead");
  const countHead = t("goodTable.countHead");

  const headTable = [];
  let isOnevariationGood = false;
  const arrayGood = {};
  const arrayPrices = [];

  let isGoodInVariation = false;

  headTable.push(array[0]?.name1);
  headTable.push(array[0]?.name2);
  headTable.push(countHead);
  headTable.push(`${priceHead}${currency}`);
  /*
  formation of the object of commodity variability,
  and adding this object to the beginning of the general variability.
  */
  const createvatiations = () => {
    const customArray = [...array];
    customArray.map(({ id }) => {
      if (id === idGood) {
        isGoodInVariation = true;
      }
      return id;
    });
    if (!isGoodInVariation) {
      specificationsGood.map((specification) => {
        return headTable.map((item) => {
          if (specification.name === item) {
            if (!isOnevariationGood) {
              isOnevariationGood = true;
              arrayGood.name1 = specification.name;
              arrayGood.value1 = specification.val;
            } else {
              arrayGood.name2 = specification.name;
              arrayGood.value2 = specification.val;
            }
          }
          return null;
        });
      });

      if (priceGood > 0) {
        arrayGood.price = priceGood;
        arrayGood.id = idGood;
        customArray?.unshift(arrayGood);
      }
    }
    customArray.map((item) => arrayPrices.push(item.price));
    setMaxPrice(Math.max(...arrayPrices));
    arrayPrices.length > 1
      ? setMinPrice(Math.min(...arrayPrices))
      : setMinPrice(0);
  };

  useEffect(() => {
    createvatiations();
  }, []);

  return (
    <table className={styles.table}>
      <thead className={styles.table__head}>
        <tr>
          {headTable.map((name) => {
            return <th key={name}>{name}</th>;
          })}
        </tr>
      </thead>
      <tbody className={styles.table__body}>
        {array.map(
          ({ id, name1, name2, value1, value2, price, quantity }, index) => {
            return (
              <tr key={index}>
                {headTable.map((item, index) => {
                  let isValue = false;
                  if (name1 === item && value1) {
                    isValue = true;
                    return <td key={index}>{value1}</td>;
                  }
                  if (name2 === item && value2) {
                    isValue = true;
                    return <td key={index}>{value2}</td>;
                  }
                  if (countHead === item) {
                    isValue = true;
                    return (
                      <td className={styles.count} key={index}>
                        <Counter
                          id={id}
                          basketGoodVariations={basketGoodVariations}
                          setBasketGoodVariations={setBasketGoodVariations}
                        />
                      </td>
                    );
                  }
                  if (item?.includes(priceHead)) {
                    isValue = true;
                    return <td key={index}>{spliter(price)}</td>;
                  }
                  if (!isValue) {
                    return <td key={index}>-</td>;
                  }
                  return null;
                })}
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
};
