import * as Yup from "yup";

// const delivery = 2;
export const MailingFormSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t("yupValidation.email"))
      .required(t("yupValidation.isRequired")),
  });
};
