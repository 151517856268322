//images
import { arrowDownIco } from "../../../ImageConstants/imageConstants";

//styles
import s from "./SliderBtns.module.scss";

const SliderBtnNext = ({ className, classNameSliderBtn }) => {
  return (
    <div
      className={`${s.sliderButton} ${s.sliderNext} ${className} ${classNameSliderBtn}`}
    >
      <img src={arrowDownIco} alt="arrowDownIco" />
    </div>
  );
};

export default SliderBtnNext;
