import React, { useState } from "react";

//styles
import styles from "./HeaderContacts.module.scss";

//images
import { arrowDownIco, phoneIco } from "../../../ImageConstants/imageConstants";

//db
import { phoneNumbersData } from "../../../Db/contactsData";

export const HeaderContacts = () => {
  const [isFocus, setFocus] = useState(false);

  return (
    <div className={styles.contacts}>
      <a href={phoneNumbersData[0]?.url}>
        <img src={phoneIco} alt="phone" />
      </a>
      <div className={styles.dropdown}>
        <PhoneNumbersLink
          phone={phoneNumbersData[0]?.label}
          link={phoneNumbersData[0]?.url}
        />

        {isFocus ? (
          <ul className={styles.dropdown__list}>
            <li className={styles.dropdown__item}>
              <PhoneNumbersLink
                phone={phoneNumbersData[1]?.label}
                link={phoneNumbersData[1]?.url}
              />
            </li>
            <li className={styles.dropdown__item}>
              <PhoneNumbersLink
                phone={phoneNumbersData[2]?.label}
                link={phoneNumbersData[2]?.url}
              />
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
      <img
        className={`${styles.arrow} ${isFocus ? styles.active : ""}`}
        src={arrowDownIco}
        alt="arrow"
        onClick={() => setFocus(!isFocus)}
      />
    </div>
  );
};

const PhoneNumbersLink = ({ phone = "", link = "/" }) => {
  return (
    <a className={styles.dropdown__link} href={link}>
      {phone}
    </a>
  );
};
