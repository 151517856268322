import React from "react";

//styles
import styles from "./CharacterTable.module.scss";

export const CharacterTable = ({ specifications, language }) => {
  return (
    <>
      {specifications.length > 0 &&
        specifications?.map((item, index) => {
          if (item.name) {
            return (
              <li key={item.id} className={styles.table_item}>
                <div className={styles.table_title}>{item?.name}</div>
                <div className={styles.table_label}>{item?.val}</div>
              </li>
            );
          } else {
            return (
              <li key={item.id} className={styles.table_item}>
                <div className={styles.table_title}>{item?.key[language]}</div>
                <div className={styles.table_label}>
                  {item?.pivot?.value[language]}
                </div>
              </li>
            );
          }
        })}
    </>
  );
};
