import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/api";

//app
import {ERROR_PAGE_ROUTE, pathFC} from "../../../Components/App/Routes/routesConstants";

export const getVideosCategoryBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetVideosCategoryBack(true));

  const success = (data) => {
    dispatch(
      setDataGetVideosCategoryBack({
        data: data.data,
        meta: data.meta,
      })
    );
  };

  const setError = () => {
    window.location.href = pathFC(ERROR_PAGE_ROUTE);
  };

  getRequest({
    url: `/video-category/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetVideosCategoryBack: null,
  metaGetVideosCategoryBack: null,
  loadingGetVideosCategoryBack: false,
  errorGetVideosCategoryBack: null,
};

export const VideosCategory = createSlice({
  name: "VideosCategory",
  initialState,
  reducers: {
    setDataGetVideosCategoryBack(state, action) {
      state.dataGetVideosCategoryBack = action.payload.data;
      state.metaGetVideosCategoryBack = action.payload.meta;
      state.loadingGetVideosCategoryBack = false;
      state.errorGetVideosCategoryBack = null;
    },

    setErrorGetVideosCategoryBack(state, action) {
      state.errorGetVideosCategoryBack = action.payload;
      state.loadingGetVideosCategoryBack = false;
    },
    setLoadingGetVideosCategoryBack(state, action) {
      state.loadingGetVideosCategoryBack = action.payload;
      state.errorGetVideosCategoryBack = null;
    },
  },
});

export const {
  setLoadingGetVideosCategoryBack,
  setErrorGetVideosCategoryBack,
  setDataGetVideosCategoryBack,
} = VideosCategory.actions;

export default VideosCategory.reducer;
