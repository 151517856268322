import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getBlogBack = (params) => (dispatch) => {
  dispatch(setLoadingGetBlogBack(true));

  const success = (data) => {
    dispatch(
      setDataGetBlogBack({
        data: data?.data,
        meta: data?.meta,
      })
    );
  };

  const setError = (error) => {
    dispatch(setErrorGetBlogBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/posts`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetBlogBack: null,
  metaGetBlogBack: null,
  loadingGetBlogBack: false,
  errorGetBlogBack: null,
};

export const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setDataGetBlogBack(state, action) {
      state.dataGetBlogBack = action.payload.data;
      state.metaGetBlogBack = action.payload.meta;
      state.loadingGetBlogBack = false;
      state.errorGetBlogBack = null;
    },

    setErrorGetBlogBack(state, action) {
      state.errorGetBlogBack = action.payload;
      state.loadingGetBlogBack = false;
    },
    setLoadingGetBlogBack(state, action) {
      state.loadingGetBlogBack = action.payload;
      state.errorGetBlogBack = null;
    },
  },
});

export const {
  setLoadingGetBlogBack,
  setErrorGetBlogBack,
  setDataGetBlogBack,
} = blog.actions;

export default blog.reducer;
