import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//styles
import styles from "./SearchBar.module.scss";

//images
import { searchIco } from "../../../ImageConstants/imageConstants";

//redux
import {
  getSearchGoodsBack,
  setDataGetSearchGoodsBack,
} from "../../../ReduxToolkit/Goods/SearchGoods/SearchGoods";

//api
import { baseURLSingle } from "../../../API/api";

//app
import { SEARCH_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

export const SearchBar = () => {
  let searchTimer;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);

  const isMobile = useSelector((state) => state.app.isMobile);

  const searchGoods = useSelector(
    (state) => state.searchGoods.dataGetSearchGoodsBack
  );

  const [search, setSearch] = useState("");

  const [activeList, setActiveList] = useState(false);

  useEffect(() => {
    const params = {
      lang: language,
      name: search,
    };
    if (search) {
      dispatch(getSearchGoodsBack(params));
    } else {
      dispatch(setDataGetSearchGoodsBack([]));
    }
  }, [search]);

  return (
    <div
      className={styles.wrapperSearchbar}
      onMouseEnter={() => setActiveList(true)}
      onMouseLeave={() => setActiveList(false)}
    >
      <div className={styles.searchbar}>
        <input
          className={styles.input}
          type="text"
          placeholder={t("header.search")}
          defaultValue={search}
          onChange={(e) => {
            clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
              setSearch(e.target.value);
            }, 3000);
          }}
        />
        <NavLink className={styles.btn} to={pathFC(`${SEARCH_PAGE_ROUTE}`)}>
          <img src={searchIco} alt="search" />
        </NavLink>
      </div>
      {searchGoods?.length > 0 && search.length > 0 && activeList && !isMobile && (
        <ul className={`${styles.dropdown__list}`}>
          {searchGoods.map(({ id, name, images, url, slug }) => {
            return (
              <NavLink
                to={pathFC(`${url}`)}
                className={styles.link}
                key={id}
              >
                <li className={styles.wrapper_good}>
                  <div className={styles.wrapper_img}>
                    <picture>
                      <source
                        srcSet={images[0]?.image_url.webp && `${baseURLSingle}/${encodeURI(images[0]?.image_url.webp.trim())}`}
                        type="image/webp"
                      />
                      <img
                        src={images[0]?.image_url.src && `${baseURLSingle}/${images[0]?.image_url.src}`}
                        alt="good"
                      />
                    </picture>
                  </div>
                  <div className={styles.wrapper_nameAndPrice}>
                    <p className={styles.name}>{name}</p>
                  </div>
                </li>
              </NavLink>
            );
          })}
        </ul>
      )}
    </div>
  );
};
