import React from "react";

//styles
import styles from "./ModalWindow.module.scss";

const ModalTitle = ({ title }) => {
  return <h4 className={styles.title}>{title}</h4>;
};

export default ModalTitle;
