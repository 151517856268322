import { createSlice } from "@reduxjs/toolkit";

//api
import { putRequest } from "../../../API/api";

//routes
import {
  setModalWindowStatus,
  setModalWindowChildrenType,
  setModalWindowInfo,
} from "../../App/app";

import { getUserBack } from "../User";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

const token = localStorage.getItem("accessTokenVectortoll");

export const putUserChangeIndividualFormBack = (obj, id) => (dispatch) => {
  dispatch(setLoadingPutUserChangeIndividualFormBack(true));

  const success = () => {
    dispatch(setLoadingPutUserChangeIndividualFormBack(false));
    dispatch(getUserBack(token));
    dispatch(
      setModalWindowInfo({
        title: "modalWindows.success.change_IndividualForm.title",
        label: "modalWindows.success.change_IndividualForm.label",
      })
    );
    dispatch(setModalWindowChildrenType("success"));
    dispatch(setModalWindowStatus(true));
  };

  const setError = (error) => {
    dispatch(setErrorPutUserChangeIndividualFormBack(error));
    dispatch(backErrorModal(error));
  };

  putRequest({
    url: `/personal-info/${id}`,
    success,
    setError,
    obj,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  dataPutUserChangeIndividualFormBack: null,
  loadingPutUserChangeIndividualFormBack: false,
  errorPutUserChangeIndividualFormBack: null,
};

export const userChangeIndividualForm = createSlice({
  name: "userChangeIndividualForm",
  initialState,
  reducers: {
    setDataPutUserChangeIndividualFormBack(state, action) {
      state.dataPutUserChangeIndividualFormBack = action.payload;
      state.loadingPutUserChangeIndividualFormBack = false;
      state.errorPutUserChangeIndividualFormBack = null;
    },

    setErrorPutUserChangeIndividualFormBack(state, action) {
      state.errorPutUserChangeIndividualFormBack = action.payload;
      state.loadingPutUserChangeIndividualFormBack = false;
    },
    setLoadingPutUserChangeIndividualFormBack(state, action) {
      state.loadingPutUserChangeIndividualFormBack = action.payload;
      state.errorPutUserChangeIndividualFormBack = null;
    },
  },
});

export const {
  setLoadingPutUserChangeIndividualFormBack,
  setErrorPutUserChangeIndividualFormBack,
  setDataPutUserChangeIndividualFormBack,
} = userChangeIndividualForm.actions;

export default userChangeIndividualForm.reducer;
