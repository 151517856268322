import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { ErrorBoundary } from "react-error-boundary";
import {ERROR_PAGE_ROUTE, pathFC} from "./Routes/routesConstants";

//routes
import { publicRoutes } from "./Routes/routes";

//components
import ErrorComponent from "../ErrorBoundaryComponent/ErrorBoundaryComponent";

const AppRouter = () => {
  const logError = (error, errorInfo) => {
    console.log({ error, errorInfo });
  };

  return (
    <Switch>
      {publicRoutes.map(({ path, component: Component }) => (
        <Route exact sensitive key={path} path={path}>
          <ErrorBoundary FallbackComponent={ErrorComponent} onError={logError}>
            <Component />
          </ErrorBoundary>
        </Route>
      ))}
      <Route>
        <Redirect to={pathFC(ERROR_PAGE_ROUTE)} />
      </Route>
    </Switch>
  );
};

export default AppRouter;
