import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../API/api";

//routes
import {
  MAIN_PAGE_ROUTE,
  pathFC,
} from "../../Components/App/Routes/routesConstants";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getUserBack = (token) => (dispatch) => {
  dispatch(setLoadingGetUserBack(true));

  const success = (data) => {
    localStorage.setItem("userId_vectorTooll", data?.data?.id);
    dispatch(setDataGetUserBack(data?.data));
  };

  const setError = (error) => {
    localStorage.removeItem("accessTokenVectortoll");
    window.location.href = pathFC(MAIN_PAGE_ROUTE);
    dispatch(setErrorGetUserBack(error));
    dispatch(backErrorModal(error));
  };

  postRequest({
    url: `/auth`,
    success,
    setError,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  dataGetUserBack: {},
  loadingGetUserBack: false,
  errorGetUserBack: null,
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDataGetUserBack(state, action) {
      state.dataGetUserBack = action.payload;
      state.loadingGetUserBack = false;
      state.errorGetUserBack = null;
    },

    setErrorGetUserBack(state, action) {
      state.errorGetUserBack = action.payload;
      state.loadingGetUserBack = false;
    },
    setLoadingGetUserBack(state, action) {
      state.loadingGetUserBack = action.payload;
      state.errorGetUserBack = null;
    },
  },
});

export const {
  setLoadingGetUserBack,
  setErrorGetUserBack,
  setDataGetUserBack,
} = user.actions;

export default user.reducer;
