import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

//styles
import styles from "./CartButton.module.scss";

//images
import { cartIco } from "../../../ImageConstants/imageConstants";

//app
import { CART_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

export const CartButton = () => {
  const dataGetBasketGoodsBack =
    useSelector((state) => state.basket.dataGetBasketGoodsBack) || [];

  return (
    <NavLink to={pathFC(CART_PAGE_ROUTE)} className={styles.cart}>
      <img src={cartIco} alt="cart" />
      {dataGetBasketGoodsBack.length > 0 && (
        <div className={styles.count}>{dataGetBasketGoodsBack.length}</div>
      )}
    </NavLink>
  );
};
