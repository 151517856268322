export const updateObjectInArray = (
  items,
  itemId,
  objPropName,
  newObjProps
) => {
  return items.map((u) => {
    if (u[objPropName] === itemId) {
      return { ...u, ...newObjProps };
    }
    return u;
  });
};

export const updateAllObjectsInArray = (items, newObjProps) => {
  return items.map((u) => ({ ...u, ...newObjProps }));
};

export const findElementOfId = (arr, id) => {
  return arr.find((el) => el.id === id);
};

export const findElementOfGood = (arr, good) => {
  return arr.find((el) => el.good === good);
};

export const findElementOfCode = (arr, codeNumber) => {
  return arr.find((el) => el.codeNumber === codeNumber);
};
