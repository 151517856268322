import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./ForgotPassword.module.scss";

//commons
import Title from "../../Title";
import Input from "../../Inputs/Input/Input";

//redux
import { postUserForgotPasswordBack } from "../../../../ReduxToolkit/User/ForgotPassword/ForgotPassword";

//utils
import { emailRegExp } from "../../../../Utils/formRegExp";

const nameFields = {
  email: "email",
};

const ForgotPassword = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { loadingPostUserForgotPasswordBack } = useSelector(
    (state) => state.userForgotPassword
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    dispatch(
      postUserForgotPasswordBack({
        obj: {
          email: data?.email,
        },
      })
    );
  };

  return (
    <div className={styles.modal}>
      <Title label={t("modalWindows.forgotPassword.title")} />

      <div className={styles.wrapper}>
        <p className={styles.description}>
          {t("modalWindows.forgotPassword.description")}.
        </p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            maxWidth={{ xl: "350px" }}
            width={{ xl: "100%" }}
            marginLeft={{ xl: "auto" }}
            marginRight={{ xl: "auto" }}
            marginTop={{ xl: "20px" }}
            name={nameFields.email}
            type="email"
            placeholder={"E-mail"}
            required
            error={errors?.[nameFields.email]}
            validation={{
              required: t("yupValidation.isRequired"),
              pattern: {
                value: emailRegExp,
                message: t("yupValidation.email"),
              },
            }}
            register={register}
          />
          <button
            className={`${styles.btn}  ${
              loadingPostUserForgotPasswordBack ? "disabledLoading" : ""
            }`}
            disabled={loadingPostUserForgotPasswordBack}
          >
            {t("modalWindows.forgotPassword.btn")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
