import React from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, Lazy, Manipulation, Keyboard } from "swiper";

//styles
import styles from "./PressImageModal.module.scss";

//api
import { baseURLSingle } from "../../../../API/api";

import SliderBtnPrev from "../../SliderBtns/SliderBtnPrev";
import SliderBtnNext from "../../SliderBtns/SliderBtnNext";

export const PressImageModal = () => {
  const pressModalObject = useSelector((state) => state.app.modalWindowInfo);

  const { images = "", id} = pressModalObject;

  const classNameSliderBtnNext = `swiper-button-next_pressImg`;
  const classNameSliderBtnPrev = `swiper-button-prev_pressImg`;

  const settings = {
    mousewheel: true,
    loop: true,
    spacebetween: 0,
    speed: 600,
    slidesPerView: 1,
    initialSlide: images.findIndex(item=> item.id === id),
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    navigation: {
      nextEl: `.${classNameSliderBtnNext}`,
      prevEl: `.${classNameSliderBtnPrev}`,
    },
  };

  return (
    <div className={styles.press}>
      <SliderBtnPrev
        classNameSliderBtn={classNameSliderBtnPrev}
        className={styles.btnPrev}
      />
      <SliderBtnNext
        classNameSliderBtn={classNameSliderBtnNext}
        className={styles.btnNext}
      />
      {images?.length > 0 && (
        <Swiper
          {...settings}
          modules={[Navigation, Pagination, Lazy, Manipulation, Keyboard]}
        >
          {images?.map((data, i) => {
            return (
              <SwiperSlide key={i}>
                <picture>
                  <source
                    srcSet={data.webp && `${baseURLSingle}/${encodeURI(data.webp.trim())}`}
                    type="image/webp"
                  />
                  <img
                    className={styles.slide__img}
                    src={data.src && `${baseURLSingle}/${data.src}`}
                    alt={data?.title}
                  />
                </picture>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};
