import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//styles
import styles from "./ModalWindow.module.scss";

//images
import { closeIco } from "../../../ImageConstants/imageConstants";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../ReduxToolkit/App/app";

export const ModalWindow = ({ children }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modalType = useSelector((state) => state.app.modalWindowChildrenType);

  const closeModalWindow = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(setModalWindowStatus(false));
      dispatch(setModalWindowChildrenType(""));
    }
  };

  return (
    <div className={styles.wrapper} onClick={closeModalWindow}>
      <div className={styles.modal}>
        <header
          className={`${styles.header} ${
            modalType === "product" && styles.is_title
          }`}
        >
          <div className={styles.title_small}>
            {modalType === "product" && "Швидкий перегляд"}
            {modalType === "requestPrice" && t("knowPrice")}
          </div>
          <div className={styles.close_btn}>
            <img onClick={closeModalWindow} src={closeIco} alt="close" />
          </div>
        </header>
        <div className={styles.inner}>{children}</div>
      </div>
    </div>
  );
};
