import React, { useEffect, useState } from "react";

//styles
import styles from "./GoodSlider.module.scss";

//images
import { arrowDownIco } from "../../../ImageConstants/imageConstants";

//api
import { baseURLSingle } from "../../../API/api";

//utils
import { createPages } from "../../../Utils/pagesCreator";
const SliderDots = ({ images, slide, setSlide, indexSlide, setIndexSlide }) => {
  const [pagess, setPages] = useState([]);

  const prevSlide = () => {
    if (indexSlide < 1) return setSlide(images[0]?.id);
    setSlide(images[indexSlide - 1]?.id);
    setIndexSlide((prev) => prev - 1);
  };

  const nextSlide = () => {
    if (indexSlide + 1 >= images.length)
      return setSlide(images[images.length - 1]?.id);
    setSlide(images[indexSlide + 1]?.id);
    setIndexSlide((prev) => prev + 1);
  };

  let pages = [];
  useEffect(() => {
    setPages(() => createPages(pages, images.length, indexSlide, images));
  }, [indexSlide, slide]);

  return (
    <div className={styles.dots}>
      <button
        onClick={prevSlide}
        className={`${styles.arrow} ${styles.prev_arrow}`}
      >
        <img src={arrowDownIco} alt="" />
      </button>
      {pagess?.map(({ id = 1, image_url, alt }, index) => (
        <div
          onClick={() => {
            setSlide(id);
            setIndexSlide(() => index);
          }}
          key={id}
          className={`${styles.dot} ${slide === id ? styles.active : ""}`}
        >
          <picture>
            <source
              srcSet={image_url.webp && `${baseURLSingle}/${encodeURI(image_url.webp.trim())}`}
              type="image/webp"
            />
            <img
              className={styles.dot_img}
              src={image_url.src && `${baseURLSingle}/${image_url.src}`}
              alt={alt}
            />
          </picture>
        </div>
      ))}
      <button
        onClick={nextSlide}
        className={`${styles.arrow} ${styles.next_arrow}`}
      >
        <img src={arrowDownIco} alt="" />
      </button>
    </div>
  );
};
// const SliderDots = ({ images, slide, setSlide, counter, setCounter }) => {
//   const prevSlide = () => {
//     if (counter > -1) {
//       counter !== 1 && setCounter((prev) => prev - 1);
//       setSlide(() => images[counter - 1].id);
//     }
//   };

//   const nextSlide = () => {
//     if (images.length > counter) {
//       setCounter((prev) => prev + 1);
//       setSlide(images[counter - 1].id);
//     }
//   };

//   return (
//     <div className={styles.dots}>
//       {images.length > 1 && (
//         <button
//           onClick={prevSlide}
//           className={`${styles.arrow} ${styles.prev_arrow}`}
//         >
//           <img src={arrowDownIco} alt="" />
//         </button>
//       )}

//       {images.map(({ id, image_url, alt }) => (
//         <button
//           onClick={() => setSlide(id)}
//           key={id}
//           className={`${styles.dot} ${slide === id ? styles.active : ""}`}
//         >
//           <img
//             className={styles.dot_img}
//             src={image_url && `${baseURLSingle}/${image_url}`}
//             alt={alt}
//           />
//         </button>
//       ))}
//       {images.length > 1 && (
//         <button
//           onClick={nextSlide}
//           className={`${styles.arrow} ${styles.next_arrow}`}
//         >
//           <img src={arrowDownIco} alt="" />
//         </button>
//       )}
//     </div>
//   );
// };

export default SliderDots;
