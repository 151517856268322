import React from "react";
import { useTranslation } from "react-i18next";

//styles
import styles from "./HeaderProductBtn.module.scss";

//commons
import BurgerButton from "../../Commons/BurgerButton";

export const HeaderProductBtn = ({ focus, setFocus }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.product}>
      <div onClick={setFocus} className={styles.product__btn}>
        <BurgerButton focus={focus} setFocus={setFocus} />
        <span className={styles.product__btn_text}>{t("header.product")}</span>
      </div>
    </section>
  );
};
