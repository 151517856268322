import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//styles
import styles from "./HeaderMobileMenu.module.scss";

//images
import {
  arrowRightIco,
  phoneIco,
} from "../../../ImageConstants/imageConstants";

//commons
import SwitchLanguage from "../../Commons/SwitchLanguage";
import ProfileButton from "../../Commons/ProfileButton";

//components
import HeaderNavigation from "../../Header/HeaderNavigation";
import HeaderAddress from "../../Header/HeaderAddress";
import HeaderMobileProductsMenuItem from "./HeaderMobileProductsMenuItem";

//db
import { phoneNumbersData } from "../../../Db/contactsData";

export const HeaderMobileMenu = ({ focus, closeMenu }) => {
  const { t } = useTranslation();

  const [isProductsMenu, setProductsMenu] = useState(false);

  const productsMenu = useSelector(
    (state) => state.categories.dataGetCategoriesBack
  );

  return (
    <section className={`${styles.menu} ${focus && styles.active}`}>
    <div className={styles.fakerTop}/>
      <div
        className={styles.products}
        onClick={() => setProductsMenu(!isProductsMenu)}
      >
        {t("crumbs.products")}
        <img
          className={`${styles.products__arrow} ${
            isProductsMenu && styles.active
          }`}
          src={arrowRightIco}
          alt="arrow"
        />
      </div>

      <ul
        className={`${styles.products__menu} ${
          isProductsMenu && styles.active
        }`}
      >
        {productsMenu?.length > 0 &&
          productsMenu.map(({ id, ...menu }) => {
            return (
              <HeaderMobileProductsMenuItem
                key={id}
                id={id}
                {...menu}
                closeMenu={closeMenu}
              />
            );
          })}
      </ul>
      <HeaderNavigation
        closeMobileMenu={closeMenu}
        closeProductsMenu={setProductsMenu}
      />
      <div className={styles.contacts}>
        <HeaderAddress />
        <ul className={styles.phone}>
          <li className={styles.phone_item}>
            <a href={phoneNumbersData[0]?.url}>
              <img src={phoneIco} alt="phone" />
            </a>
            <PhoneNumbersLink
              phone={phoneNumbersData[0]?.label}
              link={phoneNumbersData[0]?.url}
            />
          </li>
          <li className={styles.phone_item}>
            <a href={phoneNumbersData[0]?.url}>
              <img src={phoneIco} alt="phone" />
            </a>
            <PhoneNumbersLink
              phone={phoneNumbersData[1]?.label}
              link={phoneNumbersData[1]?.url}
            />
          </li>
          <li className={styles.phone_item}>
            <a href={phoneNumbersData[0]?.url}>
              <img src={phoneIco} alt="phone" />
            </a>
            <PhoneNumbersLink
              phone={phoneNumbersData[2]?.label}
              link={phoneNumbersData[2]?.url}
            />
          </li>
        </ul>
      </div>
      <div className={styles.auth} onClick={closeMenu}>
        <ProfileButton mobileLabel={t("loginRegistration")} />
      </div>
      <div className={styles.language}>
        <SwitchLanguage />
      </div>
    </section>
  );
};

const PhoneNumbersLink = ({ phone = "", link = "/" }) => {
  return (
    <a className={styles.phone_link} href={`tel:${link}`}>
      {phone}
    </a>
  );
};
