import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../../API/api";

import {
  setModalWindowStatus,
  setModalWindowChildrenType,
} from "../../App/app";
import { getUserBack } from "../User";

export const postUserLoginLoginBack = (obj) => (dispatch) => {
  dispatch(setLoadingPostUserLoginBack(true));

  const closeModalWindow = () => {
    dispatch(setModalWindowStatus(false));
    dispatch(setModalWindowChildrenType(""));
  };

  const success = (data) => {
    dispatch(setDataPostUserLoginBack(data));
    localStorage.setItem("accessTokenVectortoll", data.access_token);
    dispatch(getUserBack(data.access_token));
    closeModalWindow();
    window.location.reload();
  };

  const setError = (error) => {
    dispatch(setErrorPostUserLoginBack(error));
    // dispatch(backErrorModal(error));
  };

  postRequest({
    url: "/auth/login",
    success,
    setError,
    obj,
  });
};

const initialState = {
  dataPostUserLoginBack: null,
  loadingPostUserLoginBack: false,
  errorPostUserLoginBack: null,
};

export const userLogin = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    setDataPostUserLoginBack(state, action) {
      state.dataPostUserLoginBack = action.payload;
      state.loadingPostUserLoginBack = false;
      state.errorPostUserLoginBack = null;
    },

    setErrorPostUserLoginBack(state, action) {
      state.errorPostUserLoginBack = action.payload;
      state.loadingPostUserLoginBack = false;
    },
    setLoadingPostUserLoginBack(state, action) {
      state.loadingPostUserLoginBack = action.payload;
      state.errorPostUserLoginBack = null;
    },
  },
});

export const {
  setLoadingPostUserLoginBack,
  setErrorPostUserLoginBack,
  setDataPostUserLoginBack,
} = userLogin.actions;

export default userLogin.reducer;
