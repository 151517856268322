import { languageData, mainLanguageData } from "../../Db/languageData";

export const languageInitialization = (
  language,
  currentLocation,
  currentHistory
) => {
  let finalPathname;

  const langKey = languageData.map((item) => `/${item.key}`);

  let withoutlang = `${currentLocation.pathname}${currentLocation?.search}`;

  langKey.map((lang) => {
    if(currentLocation.pathname.startsWith(lang)) {
      withoutlang = withoutlang.replace(lang, "");
    }
    return lang;
  });

  const isLanguageMain = mainLanguageData?.key === language;
  finalPathname = isLanguageMain ? withoutlang : `/${language}${withoutlang}`;

  currentHistory.push(finalPathname);

  return true;
};
