import React from "react";

//styles
import styles from "./ArrowToTop.module.scss";

//images
import { doubleArrows } from "../../../ImageConstants/imageConstants";

export const ArrowToTop = () => {
  return (
    <a href="#top">
      <div className={styles.arrow}>
        <img src={doubleArrows} alt="arrow" />
      </div>
    </a>
  );
};
