import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//app
import {ERROR_PAGE_ROUTE, pathFC} from "../../Components/App/Routes/routesConstants";

export const getGoodsBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetGoodsBack(true));

  const success = (data) => {
    dispatch(
      setDataGetGoodsBack({
        data: data?.data,
        meta: data?.meta,
      })
    );
  };

  const setError = () => {
    window.location.href = pathFC(ERROR_PAGE_ROUTE);
  };

  getRequest({
    url: `/categories/${slug}/products`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetGoodsBack: [],
  metaGetGoodsBack: null,
  loadingGetGoodsBack: false,
  errorGetGoodsBack: null,
};

export const goods = createSlice({
  name: "goods",
  initialState,
  reducers: {
    setDataGetGoodsBack(state, action) {
      state.dataGetGoodsBack = action.payload.data;
      state.metaGetGoodsBack = action.payload.meta;
      state.loadingGetGoodsBack = false;
      state.errorGetGoodsBack = null;
    },

    setErrorGetGoodsBack(state, action) {
      state.dataGetGoodsBack = [];
      state.metaGetGoodsBack = null;
      state.loadingGetGoodsBack = false;
    },
    setLoadingGetGoodsBack(state, action) {
      state.loadingGetGoodsBack = action.payload;
      state.errorGetGoodsBack = null;
    },
  },
});

export const {
  setLoadingGetGoodsBack,
  setErrorGetGoodsBack,
  setDataGetGoodsBack,
} = goods.actions;

export default goods.reducer;
