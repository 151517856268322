import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

//styles
import styles from "./HeaderNavigation.module.scss";

//redux
import { setHeaderClearActiveProductMenu } from "../../../ReduxToolkit/App/Header/Header";

//Routes
import { pathFC } from "../../App/Routes/routesConstants";

import { navigationData } from "../../../Db/headerData";

export const HeaderNavigation = ({
  focusMenu,
  setFocusMenu,
  closeMobileMenu,
  closeProductsMenu,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <nav className={styles.menu}>
      <ul className={styles.list}>
        {navigationData.map(({ id = "", hidemobile = false, hidepc = false, url = "", label = "", childs = [] }) => (
          <li
            key={id}
            className={styles.item+' '+(hidemobile ? styles.hidemobile : '')+' '+(hidepc ? styles.hidepc : '')}
            onClick={() => {
              if (focusMenu) setFocusMenu();
              if (closeMobileMenu) {
                closeMobileMenu();
                closeProductsMenu(false);
                dispatch(setHeaderClearActiveProductMenu());
              }
            }}
          >
            { url.length ?
              (
                <NavLink
                  activeClassName={styles.active}
                  to={pathFC(url)}
                  className={styles.link}
                >
                  <span className={styles.link_text}>{t(label)}</span>
                </NavLink>
              ) :
              (
                <span className={styles.link}>{t(label)}</span>
              )
            }
            { childs && (
                <div className={styles.dropdown}>
                  {childs.map(({ url = "", label = "" }) => (
                    <NavLink
                      key={id}
                      activeClassName={styles.active}
                      to={pathFC(url)}
                      className={styles.link}
                    >
                      <span className={styles.link_text}>{t(label)}</span>
                    </NavLink>
                  ))}
                </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
