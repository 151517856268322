import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

export const getCategoriesBack = (params) => (dispatch) => {
  dispatch(setLoadingGetCategoriesBack(true));

  const success = (data) => {
    dispatch(setDataGetCategoriesBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetCategoriesBack(error));
    dispatch(backErrorModal(error));
  };

  getRequest({
    url: `/categories_menu`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetCategoriesBack: [],
  loadingGetCategoriesBack: false,
  errorGetCategoriesBack: null,
};

export const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setDataGetCategoriesBack(state, action) {
      state.dataGetCategoriesBack = action.payload;
      state.loadingGetCategoriesBack = false;
      state.errorGetCategoriesBack = null;
    },

    setErrorGetCategoriesBack(state, action) {
      state.errorGetCategoriesBack = action.payload;
      state.loadingGetCategoriesBack = false;
    },
    setLoadingGetCategoriesBack(state, action) {
      state.loadingGetCategoriesBack = action.payload;
      state.errorGetCategoriesBack = null;
    },
  },
});

export const {
  setLoadingGetCategoriesBack,
  setErrorGetCategoriesBack,
  setDataGetCategoriesBack,
} = categories.actions;

export default categories.reducer;
