import { createSlice } from "@reduxjs/toolkit";
import { Cookies } from 'react-cookie';

//api
import { getRequest } from "../../API/api";

//utils
import { backErrorModal } from "../../Utils/BackErrorModal";

//get goods
export const getBasketGoodsBack = (params) => (dispatch) => {
  dispatch(setLoadingGetBaskettGoodsBack(true));

  const success = (data) => {
    dispatch(setDataGetBasketGoodsBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetBasketGoodsBack(error));
    dispatch(backErrorModal(error));
  };

  const cookies = new Cookies()
  params.products = cookies.get('basket') ?? [];

  getRequest({
    url: `/cart/show`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetBasketGoodsBack: [],
  loadingGetBasketGoodsBack: false,
  errorGetBasketGoodsBack: null,
};

export const Basket = createSlice({
  name: "Basket",
  initialState,
  reducers: {
    setDataGetBasketGoodsBack(state, action) {
      state.dataGetBasketGoodsBack = action.payload;
      state.loadingGetBasketGoodsBack = false;
      state.errorGetBasketGoodsBack = null;
    },
    setErrorGetBasketGoodsBack(state, action) {
      state.errorGetBasketGoodsBack = action.payload;
      state.loadingGetBasketGoodsBack = false;
    },
    setLoadingGetBaskettGoodsBack(state, action) {
      state.loadingGetBasketGoodsBack = action.payload;
      state.errorGetBasketGoodsBack = null;
    },
  },
});

export const {
  setLoadingGetBaskettGoodsBack,
  setErrorGetBasketGoodsBack,
  setDataGetBasketGoodsBack,
} = Basket.actions;

export default Basket.reducer;
