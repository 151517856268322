import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../../API/api";

//app
import {ERROR_PAGE_ROUTE, pathFC} from "../../../Components/App/Routes/routesConstants";

export const getCategoryBack = (slug, params) => (dispatch) => {
  dispatch(setLoadingGetCategoryBack(true));

  const success = (data) => {
    dispatch(setDataGetCategoryBack(data?.data));
  };

  const setError = () => {
    window.location.href = pathFC(ERROR_PAGE_ROUTE);
  };

  getRequest({
    url: `/categories/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetCategoryBack: null,
  loadingGetCategoryBack: false,
  errorGetCategoryBack: null,
};

export const category = createSlice({
  name: "category",
  initialState,
  reducers: {
    setDataGetCategoryBack(state, action) {
      state.dataGetCategoryBack = action.payload;
      state.loadingGetCategoryBack = false;
      state.errorGetCategoryBack = null;
    },

    setErrorGetCategoryBack(state, action) {
      state.errorGetCategoryBack = action.payload;
      state.loadingGetCategoryBack = false;
    },
    setLoadingGetCategoryBack(state, action) {
      state.loadingGetCategoryBack = action.payload;
      state.errorGetCategoryBack = null;
    },
  },
});

export const {
  setLoadingGetCategoryBack,
  setErrorGetCategoryBack,
  setDataGetCategoryBack,
} = category.actions;

export default category.reducer;
