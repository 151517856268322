import { createSlice } from "@reduxjs/toolkit";

import { productMenuData } from "../../../Db/headerData";

import {
  updateAllObjectsInArray,
  updateObjectInArray,
} from "../../../Utils/objectHelper";

const initialState = {
  productMenu: productMenuData,
  focusBurger: false,
};

export const header = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderActiveProductMenu(state, action) {
      state.productMenu = updateObjectInArray(
        state.productMenu,
        action.id,
        "id",
        {
          listActive: action.payload.active,
        }
      );
    },

    setHeaderClearActiveProductMenu(state, action) {
      state.productMenu = updateAllObjectsInArray(state.productMenu, {
        listActive: false,
      });
    },
    setFocusBurger(state, action) {
      state.focusBurger = action.payload;
    },
  },
});

export const {
  setHeaderActiveProductMenu,
  setHeaderClearActiveProductMenu,
  setFocusBurger,
} = header.actions;

export default header.reducer;
