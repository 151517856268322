import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import styles from "./RegiterModal.module.scss";

//commons
import ModalTitle from "../ModalTitle";
import { RegisterFormSchema } from "./RegisterFormSchema";

//components

import Input from "../../Inputs/Input/Input";
//redux
import { postUserRegistrationBack } from "../../../../ReduxToolkit/User/Registration/Registration";

const nameFields = {
  name: "name",
  email: "email",
  password: "password",
  password_confirmation: "password_confirmation",
};

export const RegisterModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { errorPostUserRegistrationBack } = useSelector(
    (state) => state.userRegistration
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(RegisterFormSchema(t)),
  });

  const onSubmit = (data) => {
    dispatch(postUserRegistrationBack(data));
  };
  return (
    <div className={styles.modal}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <ModalTitle title={t("modalWindows.register.title")} />

        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.name}
          name={nameFields.name}
          type="text"
          placeholder={t("modalWindows.register.inputs.name")}
          required
          error={errors?.name}
          // errorFromBack={errorPostUser?.nameUser}
          register={register}
        />

        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.email}
          name={nameFields.email}
          type="text"
          placeholder={t("modalWindows.register.inputs.email")}
          required
          error={errors?.email}
          errorFromBack={errorPostUserRegistrationBack?.email}
          register={register}
        />

        <Input
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.password}
          name={nameFields.password}
          type="text"
          placeholder={t("modalWindows.register.inputs.password")}
          required
          error={errors?.password}
          // errorFromBack={errorPostUser?.nameUser}
          register={register}
        />
        <Input
          maxWidth={{ xl: "400px" }}
          width={{ xl: "100%" }}
          marginTop={{ xl: "20px" }}
          id={nameFields.password_confirmation}
          name={nameFields.password_confirmation}
          type="text"
          placeholder={t("modalWindows.register.inputs.password_confirmation")}
          required
          error={errors?.password_confirmation}
          // errorFromBack={errorPostUser?.nameUser}
          register={register}
        />

        <button className={styles.btn} type="submit">
          {t("modalWindows.register.button")}
        </button>
      </form>
    </div>
  );
};
