import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//styles
import styles from "./PaytmentModal.module.scss";

//commons
import Title from "../../Title";

export const PaytmentModal = () => {
  const { t } = useTranslation();

  const payButton = useSelector((state) => state.order.payButton);

  return (
    <div className={styles.modal}>
      <Title label={t("confirmPaymentCard")} />
      <div
        dangerouslySetInnerHTML={{ __html: [payButton] }}
        className={styles.btn}
      />
    </div>
  );
};
