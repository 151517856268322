import { lazy } from "react";
import {
  BLOG_PAGE_ROUTE,
  CART_PAGE_ROUTE,
  COMPANY_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  DELIVERY_PAGE_ROUTE,
  GUARANTEE_PAGE_ROUTE,
  PRIVACY_PAGE_ROUTE,
  OFFER_PAGE_ROUTE,
  ERROR_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  ORDER_PAGE_ROUTE,
  PRODUCTS_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
  THANKS_PAGE_ROUTE,
  VIDEO_PAGE_ROUTE,
  SEARCH_PAGE_ROUTE,
  pathFC,
} from "./routesConstants";

const BlogPage = lazy(() => import("../../BlogPage"));
const BlogPageArticle = lazy(() => import("../../BlogPage/BlogPageArticle"));
const CartPage = lazy(() => import("../../CartPage"));
const CompanyPage = lazy(() => import("../../CompanyPage"));
const ErrorPage = lazy(() => import("../../ErrorPage"));
const MainPage = lazy(() => import("../../MainPage"));
const NewsPage = lazy(() => import("../../NewsPage"));
const NewsPageArticle = lazy(() => import("../../NewsPage/NewsPageArticle"));
const ProductsPage = lazy(() => import("../../ProductsPage"));
const ProductsPageGoodContent = lazy(() =>
  import("../../ProductsPage/ProductsPageGoodContent")
);
const ContactsPage = lazy(() => import("../../ContactsPage"));
const OrderPage = lazy(() => import("../../OrderPage"));
const DeliveryPage = lazy(() => import("../../DeliveryPage"));
const GuaranteePage = lazy(() => import("../../GuaranteePage"));
const PrivacyPage = lazy(() => import("../../PrivacyPage"));
const OfferPage = lazy(() => import("../../OfferPage"));
const ThanksPage = lazy(() => import("../../ThanksPage"));
const ProductsPageCategory = lazy(() =>
  import("../../ProductsPage/ProductsPageCategory")
);
const ProductsPageSubcategory = lazy(() =>
  import("../../ProductsPage/ProductsPageSubcategory")
);

const ProductsPageGoodIntro = lazy(() =>
  import("../../ProductsPage/ProductsPageGoodIntro")
);
const VideoPage = lazy(() => import("../../VideoPage"));

const ProfilePage = lazy(() => import("../../ProfilePage"));
const ProductsSearch = lazy(() => import("../../ProductsPage/ProductsSearch"));

export const publicRoutes = [
  {
    path: pathFC(MAIN_PAGE_ROUTE),
    component: MainPage,
  },
  {
    path: [
      pathFC(NEWS_PAGE_ROUTE),
      pathFC(`${NEWS_PAGE_ROUTE}/page-:page`),
    ],
    component: NewsPage,
  },
  {
    path: pathFC(`${NEWS_PAGE_ROUTE}/:slug`),
    component: NewsPageArticle,
  },
  {
    path: [
      pathFC(BLOG_PAGE_ROUTE),
      pathFC(`${BLOG_PAGE_ROUTE}/page-:page`),
    ],
    component: BlogPage,
  },
  {
    path: pathFC(`${BLOG_PAGE_ROUTE}/:slug`),
    component: BlogPageArticle,
  },
  {
    path: pathFC(`${COMPANY_PAGE_ROUTE}`),
    component: CompanyPage,
  },
  {
    path: pathFC(PRODUCTS_PAGE_ROUTE),
    component: ProductsPage,
  },
  {
    path: pathFC(`${PRODUCTS_PAGE_ROUTE}/:category`),
    component: ProductsPageCategory,
  },
  {
    path: pathFC(`${PRODUCTS_PAGE_ROUTE}/:category/:subcategory`),
    component: ProductsPageSubcategory,
  },
  {
    path: [
      pathFC(`${PRODUCTS_PAGE_ROUTE}/:category/:subcategory/:good`),
      pathFC(`${PRODUCTS_PAGE_ROUTE}/:category/:subcategory/:good/page-:page`)
    ],
    component: ProductsPageGoodIntro,
  },
  {
    path: pathFC(`${PRODUCTS_PAGE_ROUTE}/:category/:subcategory/:good/:codeNumber`),
    component: ProductsPageGoodContent,
  },
  {
    path: pathFC(CART_PAGE_ROUTE),
    component: CartPage,
  },
  {
    path: pathFC(CONTACTS_PAGE_ROUTE),
    component: ContactsPage,
  },
  {
    path: pathFC(ORDER_PAGE_ROUTE),
    component: OrderPage,
  },
  {
    path: pathFC(DELIVERY_PAGE_ROUTE),
    component: DeliveryPage,
  },
  {
    path: pathFC(GUARANTEE_PAGE_ROUTE),
    component: GuaranteePage,
  },
  {
    path: pathFC(PRIVACY_PAGE_ROUTE),
    component: PrivacyPage,
  },
  {
    path: pathFC(OFFER_PAGE_ROUTE),
    component: OfferPage,
  },
  {
    path: pathFC(`${THANKS_PAGE_ROUTE}/:id`),
    component: ThanksPage,
  },
  {
    path: [
      pathFC(VIDEO_PAGE_ROUTE),
      pathFC(`${VIDEO_PAGE_ROUTE}/page-:page`),
    ],
    component: VideoPage,
  },
  {
    path: pathFC(`${VIDEO_PAGE_ROUTE}/:slug`),
    component: VideoPage,
  },
  {
    path: pathFC(`${PROFILE_PAGE_ROUTE}/:slug`),
    component: ProfilePage,
  },
  {
    path: pathFC(`${SEARCH_PAGE_ROUTE}`),
    component: ProductsSearch,
  },
  {
    path: pathFC(ERROR_PAGE_ROUTE),
    component: ErrorPage,
  },
];
