import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLSingle = process.env.REACT_APP_BASE_URL_SINGLE;
export const API_Key = process.env.REACT_APP_API_KEY;

const headerss = {};
var sourceSearch = null;

export const combineURLs = (baseURL, relativeURL) => {
  return relativeURL
    ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
    : baseURL;
};

export const postRequest = ({
  url,
  obj,
  success,
  setError,
  headers,
  params,
}) => {
  headers = headers ? headers : headerss;
  axios
    .post(`${combineURLs(baseURL, url)}`, obj, { headers, params })
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      setError(err);
      console.dir(err);
    });
};

export const getRequest = ({ url, success, setError, params, headers }) => {
  headers = headers ? headers : headerss;
  headers["referrerPolicy"] = "origin-when-cross-origin";
  axios
    .get(`${combineURLs(baseURL, url)}`, {
      headers,
      params,
    })
    .then((res) => success(res.data))
    .catch((err) => {
      setError(err);
    });
};

export const getSearchRequest = ({ url, success, setError, params, headers }) => {
  headers = headers ? headers : headerss;
  headers["referrerPolicy"] = "origin-when-cross-origin";

  if(sourceSearch) sourceSearch.cancel();
  sourceSearch = axios.CancelToken.source();

  axios
    .get(`${combineURLs(baseURL, url)}`, {
      headers,
      params,
      cancelToken: sourceSearch.token,
    })
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      setError(err);
    });
};

export const putRequest = ({ url, obj, success, setError, headers }) => {
  headers = headers ? headers : headerss;
  axios
    .put(`${combineURLs(baseURL, url)}`, obj, {
      headers,
    })
    .then((res) => success(res.data))
    .catch((err) => setError(err));
};
