import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../../API/api";

//routes
import {
  MAIN_PAGE_ROUTE,
  pathFC,
} from "../../../Components/App/Routes/routesConstants";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

export const postUserLogoutBack = (token) => (dispatch) => {
  dispatch(setLoadingPostUserLogoutBack(true));

  const success = () => {
    window.location.href = pathFC(MAIN_PAGE_ROUTE);
    localStorage.clear("accessTokenVectortoll");
  };

  const setError = (error) => {
    dispatch(setErrorPostUserLogoutBack(error));
    dispatch(backErrorModal(error));
  };

  postRequest({
    url: "/auth/logout",
    success,
    setError,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
};

const initialState = {
  dataPostUserLogoutBack: null,
  loadingPostUserLogoutBack: false,
  errorPostUserLogoutBack: null,
};

export const userLogout = createSlice({
  name: "userLogout",
  initialState,
  reducers: {
    setDataPostUserLogoutBack(state, action) {
      state.dataPostUserLogoutBack = action.payload;
      state.loadingPostUserLogoutBack = false;
      state.errorPostUserLogoutBack = null;
    },

    setErrorPostUserLogoutBack(state, action) {
      state.errorPostUserLogoutBack = action.payload;
      state.loadingPostUserLogoutBack = false;
    },
    setLoadingPostUserLogoutBack(state, action) {
      state.loadingPostUserLogoutBack = action.payload;
      state.errorPostUserLogoutBack = null;
    },
  },
});

export const {
  setLoadingPostUserLogoutBack,
  setErrorPostUserLogoutBack,
  setDataPostUserLogoutBack,
} = userLogout.actions;

export default userLogout.reducer;
