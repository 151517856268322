import React, { useState } from "react";

//styles
import styles from "./Counter.module.scss";

export const Counter = ({
  id,
  basketGoodVariations,
  setBasketGoodVariations,
}) => {
  const [count, setCount] = useState(0);

  return (
    <div>
      <div className={styles.count}>
        <button onClick={() => {
          if(count > 0) {
            basketGoodVariations[id] = count-1;
            setBasketGoodVariations(basketGoodVariations);
            setCount(count-1);
          }
        }}
          className={styles.count__btn}
        >
          -
        </button>
        {count}
        <button onClick={() => {
          basketGoodVariations[id] = count+1;
          setBasketGoodVariations(basketGoodVariations);
          setCount(count+1)
        }}
          className={styles.count__btn}
        >
          +
        </button>
      </div>
    </div>
  );
};
