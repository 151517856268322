export const languageData = [
  { key: "uk", label: "укр", language: "Ukrainian" },
  { key: "ru", label: "рус", language: "Russian" },
];

export const mainLanguageData = {
  key: "uk",
  label: "укр",
  language: "Ukrainian",
};
