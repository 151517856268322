import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";

//styles
import "./index.scss";

//images
import { loader } from "./ImageConstants/imageConstants";

//components
import App from "./Components/App";

//redux
import { store as storeRedux } from "./ReduxToolkit/store";

//other
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="loader">
          <img
            style={{ width: "300px", height: "300px" }}
            src={loader}
            alt="loader"
          />
        </div>
      }
    >
      <Provider store={storeRedux}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
