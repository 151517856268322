import { createSlice } from "@reduxjs/toolkit";

//api
import { getSearchRequest } from "../../../API/api";

//utils
import { backErrorModal } from "../../../Utils/BackErrorModal";

export const getSearchGoodsBack = (params) => (dispatch) => {
  dispatch(setLoadingGetSearchGoodsBack(true));

  const success = (data) => {
    dispatch(setDataGetSearchGoodsBack(data.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetSearchGoodsBack(error));
    dispatch(backErrorModal(error));
  };

  getSearchRequest({
    url: `/search`,
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetSearchGoodsBack: null,
  loadingGetSearchGoodsBack: false,
  errorGetSearchGoodsBack: null,
};

export const searchGoods = createSlice({
  name: "searchGoods",
  initialState,
  reducers: {
    setDataGetSearchGoodsBack(state, action) {
      state.dataGetSearchGoodsBack = action.payload;
      state.loadingGetSearchGoodsBack = false;
      state.errorGetSearchGoodsBack = null;
    },

    setErrorGetSearchGoodsBack(state, action) {
      state.errorGetSearchGoodsBack = action.payload;
      state.loadingGetSearchGoodsBack = false;
    },
    setLoadingGetSearchGoodsBack(state, action) {
      state.loadingGetSearchGoodsBack = action.payload;
      state.errorGetSearchGoodsBack = null;
    },
  },
});

export const {
  setLoadingGetSearchGoodsBack,
  setErrorGetSearchGoodsBack,
  setDataGetSearchGoodsBack,
} = searchGoods.actions;

export default searchGoods.reducer;
