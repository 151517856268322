import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

export const getVideosAllBack = (params) => (dispatch) => {
  dispatch(setLoadingGetVideosAllBack(true));

  const success = (data) => {
    dispatch(
      setDataGetVideosAllBack({
        data: data?.data,
        meta: data?.meta,
      })
    );
  };

  const setError = (error) => {
    dispatch(setErrorGetVideosAllBack(error));
  };

  getRequest({
    url: "/video",
    success,
    setError,
    params,
  });
};

export const getVideoCategoriesBack = (params) => (dispatch) => {
  dispatch(setLoadingGetVideoCategoriesBack(true));

  const success = (data) => {
    dispatch(setDataGetVideoCategoriesBack(data?.data));
  };

  const setError = (error) => {
    dispatch(setErrorGetVideoCategoriesBack(error));
  };

  getRequest({
    url: "/video-category",
    success,
    setError,
    params,
  });
};

const initialState = {
  dataGetVideosAllBack: [],
  metaGetVideosAllBack: null,
  loadingGetVideosAllBack: false,
  errorGetVideosAllBack: null,

  dataGetVideoCategoriesBack: [],
  loadingGetVideoCategoriesBack: false,
  errorGetVideoCategoriesBack: null,
};

export const videos = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setDataGetVideosAllBack(state, action) {
      state.dataGetVideosAllBack = action.payload.data;
      state.metaGetVideosAllBack = action.payload.meta;
      state.loadingGetVideosAllBack = false;
      state.errorGetVideosAllBack = null;
    },

    setErrorGetVideosAllBack(state, action) {
      state.errorGetVideosAllBack = action.payload;
      state.loadingGetVideosAllBack = false;
    },
    setLoadingGetVideosAllBack(state, action) {
      state.loadingGetVideosAllBack = action.payload;
      state.errorGetVideosAllBack = null;
    },
    setDataGetVideoCategoriesBack(state, action) {
      state.dataGetVideoCategoriesBack = action.payload;
      state.loadingGetVideoCategoriesBack = false;
      state.errorGetVideoCategoriesBack = null;
    },

    setErrorGetVideoCategoriesBack(state, action) {
      state.errorGetVideoCategoriesBack = action.payload;
      state.loadingGetVideoCategoriesBack = false;
    },
    setLoadingGetVideoCategoriesBack(state, action) {
      state.loadingGetVideoCategoriesBack = action.payload;
      state.errorGetVideoCategoriesBack = null;
    },
  },
});

export const {
  setLoadingGetVideosAllBack,
  setErrorGetVideosAllBack,
  setDataGetVideosAllBack,
  setDataGetVideoCategoriesBack,
  setLoadingGetVideoCategoriesBack,
  setErrorGetVideoCategoriesBack,
} = videos.actions;

export default videos.reducer;
