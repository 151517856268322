import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./ProfileButton.module.scss";

//images
import { lockIco, profileIco } from "../../../ImageConstants/imageConstants";

//app
import { PROFILE_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

//redux
import {
  setModalWindowChildrenType,
  setModalWindowStatus,
} from "../../../ReduxToolkit/App/app";

const defaultAva = profileIco;

export const ProfileButton = ({ ava = defaultAva, mobileLabel }) => {
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.app.isAuth);

  const user = useSelector((state) => state.user.dataGetUserBack);

  const openModalWindow = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(setModalWindowStatus(true));
      dispatch(setModalWindowChildrenType("auth"));
    }
  };

  return (
    <div className={styles.profile} onClick={openModalWindow}>
      {isAuth ? (
        <NavLink
          className={styles.link}
          to={pathFC(`${PROFILE_PAGE_ROUTE}/info`)}
        >
          <img className={styles.ava} src={ava} alt="avatar" />
          {isAuth && <div className={styles.name}>{user?.name}</div>}
        </NavLink>
      ) : (
        <div className={styles.non_auth} onClick={openModalWindow}>
          <img
            className={styles.ava}
            src={ava}
            alt="avatar"
            onClick={openModalWindow}
          />
          <img
            className={styles.status}
            src={lockIco}
            alt="lock"
            onClick={openModalWindow}
          />
          {mobileLabel && mobileLabel}
        </div>
      )}
    </div>
  );
};
