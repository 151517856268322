import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const ConsultFormSchema = (t) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t("yupValidation.isRequired"))
      .min(
        2,
        `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
      )
      .max(
        100,
        `${t(`yupValidation.maxSymbols`)} 100 ${t(`yupValidation.symbolss`)}`
      ),

    phone: Yup.string()
      .required(t("yupValidation.isRequired"))
      .matches(phoneRegExp, t("yupValidation.phoneRegExp"))
      .min(
        10,
        `${t(`yupValidation.minSymbols`)} 10 ${t(`yupValidation.symbols`)}`
      )
      .max(
        13,
        `${t(`yupValidation.maxSymbols`)} 13 ${t(`yupValidation.symbolss`)}`
      ),
  });
