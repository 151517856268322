import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

//styles
import styles from "./HeaderProductMenu.module.scss";

//images
import { arrowRightIco } from "../../../ImageConstants/imageConstants";

//app
import { PRODUCTS_PAGE_ROUTE, pathFC } from "../../App/Routes/routesConstants";

//components
import HeaderProductSubmenu from "./HeaderProductSubmenu";

export const HeaderProductMenu = ({ focus, setFocus, blockScroll }) => {
  const { t } = useTranslation();

  const productMenu = useSelector(
    (state) => state.categories.dataGetCategoriesBack
  );

  return (
    <section
      className={`${styles.menu} ${focus && styles.active}`}
      onClick={(e) => {
        if (e.target === e.currentTarget && blockScroll) setFocus();
      }}
    >
      <div className="container">
        <div className={`${styles.wrapper} ${focus && styles.active}`}>
          <div className={styles.left}>
            <HeaderProductSubmenu
              focusMenu={focus}
              setFocus={setFocus}
              productMenu={productMenu}
            />
            <NavLink
              onClick={setFocus}
              className={styles.link}
              to={pathFC(`${PRODUCTS_PAGE_ROUTE}`)}
            >
              {t("header.toProducts")}
              <div className={styles.link_img}>
                <img src={arrowRightIco} alt="arrow" />
              </div>
            </NavLink>
          </div>
          <div className={styles.right}>
            <div className={styles.right__label}>{t("header.question")}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
